<template>
  <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.3195 8.40207C4.15048 8.7488 3.63255 8.62475 3.63255 8.23292L3.01432 5.51131L0.361473 4.87706C-0.0218408 4.87706 -0.137232 4.34288 0.196592 4.17231L7.46716 0.442911C7.75845 0.278002 8.12247 0.614896 7.9549 0.94329L4.3195 8.40207Z" fill="#001254"/>
  </svg>
</template>

<script>
  export default {
    name: 'NavIcon'
  }
</script>
