<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.72925 14.636H5.68034C5.03829 14.636 4.51495 15.1729 4.51495 15.8316V16.2926H0.508039C0.227519 16.2926 0.000287176 16.5257 0.000287176 16.8135C0.000287176 17.1013 0.227535 17.3344 0.508039 17.3344H4.51495V17.7954C4.51495 18.4541 5.03845 18.9912 5.68034 18.9912H5.72925C6.37097 18.9912 6.89464 18.4541 6.89464 17.7954V17.3258C6.9247 17.3316 6.95538 17.3345 6.987 17.3345H18.4922C18.7727 17.3345 18.9999 17.1013 18.9999 16.8135C18.9999 16.5258 18.7727 16.2926 18.4922 16.2926H6.987C6.95538 16.2926 6.9247 16.2955 6.89464 16.3013V15.8317C6.89464 15.173 6.37097 14.6361 5.72925 14.6361V14.636ZM0.507784 2.69844C0.227264 2.69844 3.19423e-05 2.4653 3.19423e-05 2.17753C3.19423e-05 1.88976 0.22728 1.65662 0.507784 1.65662H3.51578V1.19559C3.51578 0.537234 4.03928 0 4.68117 0H4.73008C5.37212 0 5.89546 0.53707 5.89546 1.19559V1.67339C5.93643 1.66236 5.97926 1.65661 6.02334 1.65661H18.4923C18.7728 1.65661 19 1.88975 19 2.17752C19 2.4653 18.7728 2.69844 18.4923 2.69844H6.02334C5.9791 2.69844 5.93627 2.69268 5.89546 2.68166V3.15945C5.89546 3.81814 5.37196 4.35517 4.73008 4.35517H4.68117C4.03944 4.35517 3.51578 3.8181 3.51578 3.15945V2.69843L0.507784 2.69844ZM14.261 8.9057H18.492C18.7725 8.9057 18.9998 9.13884 18.9998 9.42661C18.9998 9.7144 18.7725 9.94753 18.492 9.94753H14.261V10.4085C14.261 11.0672 13.7375 11.6041 13.0956 11.6041H13.0467C12.4047 11.6041 11.8812 11.0672 11.8812 10.4085V9.9402C11.8538 9.945 11.8256 9.94739 11.7968 9.94739L0.507752 9.94723C0.227232 9.94723 0 9.7141 0 9.42632C0 9.13855 0.227248 8.90541 0.507752 8.90541H11.7968C11.8256 8.90541 11.8538 8.90781 11.8812 8.9126V8.44442C11.8812 7.78606 12.4047 7.24882 13.0467 7.24882H13.0956C13.7377 7.24882 14.261 7.78589 14.261 8.44442V8.9057Z" fill="#001254"/>
  </svg>
</template>

<script>
  export default {
    name: 'FilterIcon'
  }
</script>
