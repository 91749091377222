import axios from "axios";

export default {
  state: {
    allFilters: [],
    filters: [],
    dietaryPreferences: [],
    selectedFilters: [],
    selectedPreferences: [],
    keyword: ''
  },
  getters: {
    allFilters: state => state.allFilters,
    filters: state => state.filters,
    dietaryPreferences: state => state.dietaryPreferences,
    selectedFilters: state => state.selectedFilters,
    selectedPreferences: state => state.selectedPreferences,
    keyword: state => state.keyword
  },
  mutations: {
    setAllFilters(state, filtersObj) {
      state.allFilters = filtersObj;
    },
    setFilters(state, filtersObj) {
      state.filters = filtersObj;
    },
    setDietaryPreferences(state, preferencesObj) {
      state.dietaryPreferences = preferencesObj;
    },
    setSelectedFilters(state, filtersObj) {
      state.selectedFilters = filtersObj;
    },
    setSelectedPreferences(state, preferencesObj) {
      state.selectedPreferences = preferencesObj;
    },
    setKeyword(state, value) {
      state.keyword = value;
    },
    resetFilters(state) {
      state.selectedFilters = [];
      state.selectedPreferences = [];
      state.keyword = '';
    }
  },
  actions: {
    getFilters({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_BASE_URL}/wecook/tag/list`, {}).then(res => {
          let filtersObj = res.data.filter((el) => {
            return el.type === 'filter';
          });
  
          let preferencesObj = res.data.filter((el) => {
            return el.type === 'preference';
          });
  
          commit('setFilters', filtersObj);
          commit('setDietaryPreferences', preferencesObj);
          commit('setAllFilters', res.data);
          resolve();
        }).catch(er => {
          console.log(er);
          reject();
        });
      })
    },
    filterDishes({commit, state}, allDishes) {
      return new Promise((resolve, reject) => {
        let filtersResult = [];
        let allFilters = [...state.selectedFilters, ...state.selectedPreferences];
        if (allFilters.length != 0 || state.keyword != '') {
          allDishes.forEach((dish) => {
            dish.tags.forEach((tag) => {
              let matchedTag = allFilters.find(fil => fil.id === tag.id);
              let dishAlreadyInList = filtersResult.find(el => el.id == dish.id);
              if (matchedTag && dishAlreadyInList == undefined) {
                filtersResult.push(dish);
              }
            });
            if (state.keyword != '') {
              let positionInTitle = dish.title.toLowerCase().search(state.keyword);
              let positionInDescription = dish.description.toLowerCase().search(state.keyword);
              let dishAlreadyInList = filtersResult.find(el => el.id == dish.id);
              if ((positionInTitle != -1 || positionInDescription != -1) && (dishAlreadyInList == undefined)) {
                filtersResult.push(dish);
              }
            }
          });
          resolve(filtersResult);
        }
      });
    },
    resetFilters({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetFilters');
        resolve();
      })
    },
  }
}
