<template>
    <svg width="44" height="34" viewBox="0 0 44 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="44" height="34" rx="17" fill="white"/>
        <path d="M22.2159 26.2602C22.148 26.3088 22.0867 26.3523 22.0326 26.3905C21.9785 26.3523 21.9172 26.3088 21.8492 26.2602C21.5508 26.0469 21.1236 25.7361 20.6106 25.3476C19.5838 24.57 18.217 23.4837 16.8521 22.2462C15.4848 21.0065 14.1326 19.627 13.1261 18.2635C12.1072 16.8834 11.5 15.5958 11.5 14.5267C11.5 13.1701 12.0237 11.6499 12.9861 10.4745C13.9417 9.30743 15.3107 8.50024 17.0132 8.50024C18.7237 8.50024 19.8541 9.25273 20.5673 10.0091C20.9266 10.3901 21.1793 10.7721 21.3415 11.058C21.4223 11.2005 21.48 11.3179 21.5166 11.3976C21.535 11.4374 21.548 11.4677 21.556 11.4869L21.5642 11.507L21.5652 11.5096L21.5654 11.51L21.5655 11.5103L21.5655 11.5104L21.5655 11.5105L22.0323 12.7406L22.4995 11.5106L22.4995 11.5106L22.4995 11.5105L22.4997 11.5102L22.4998 11.5098L22.5009 11.5072L22.5091 11.487C22.5171 11.4679 22.5301 11.4376 22.5485 11.3978C22.5851 11.3181 22.6429 11.2007 22.7238 11.0581C22.8861 10.7722 23.1389 10.3902 23.4983 10.0091C24.2118 9.2527 25.3422 8.50024 27.0519 8.50024C28.7549 8.50024 30.1238 9.30744 31.0793 10.4746C32.0416 11.65 32.5651 13.1705 32.5651 14.5278C32.5651 15.597 31.9579 16.8846 30.939 18.2647C29.9325 19.628 28.5803 21.0074 27.213 22.247C25.8482 23.4842 24.4813 24.5704 23.4546 25.3478C22.9416 25.7363 22.5144 26.047 22.2159 26.2602Z" fill="#001254" stroke="#001254"/>
    </svg> 
</template>

<script>
    export default {
        name: 'FavouriteBtnEnabled'
    }
</script>
  