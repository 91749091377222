<template>
	<svg width="18" height="2" viewBox="0 0 18 2" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M16.9012 0.000451109H9.89209C9.85657 0.000490377 9.55824 4.34803e-06 9.14648 4.34803e-06C8.73472 4.34803e-06 8.40108 0.000222871 8.40108 0.000222871L1.39199 0C0.980243 0.000353385 0.646484 0.447531 0.646484 0.999998C0.646484 1.55246 0.980226 1.99968 1.39199 1.99968H8.40075C8.40075 1.99968 8.73475 2 9.14648 2C9.55822 2 9.89198 2 9.89198 2L16.9007 1.99955C17.3128 1.99955 17.6465 1.55237 17.6465 0.999863C17.6465 0.44736 17.3129 0.000451109 16.9012 0.000451109Z" fill="#001254"/>
	</svg>
</template>

<script>
  export default {
    name: 'MinusBtn'
  }
</script>
