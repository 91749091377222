<template>
  <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.2159 18.2602C11.148 18.3088 11.0867 18.3523 11.0326 18.3905C10.9785 18.3523 10.9172 18.3088 10.8492 18.2602C10.5508 18.0469 10.1236 17.7361 9.61059 17.3476C8.58383 16.57 7.21699 15.4837 5.85212 14.2462C4.48479 13.0065 3.13263 11.627 2.1261 10.2635C1.10723 8.88342 0.5 7.59577 0.5 6.52674C0.5 5.17006 1.0237 3.64987 1.98612 2.47448C2.94172 1.30743 4.31071 0.500244 6.01316 0.500244C7.72369 0.500244 8.85407 1.25273 9.56732 2.00909C9.92664 2.39012 10.1793 2.7721 10.3415 3.05801C10.4223 3.20054 10.48 3.31794 10.5166 3.39764C10.535 3.43745 10.548 3.46772 10.556 3.48688L10.5642 3.50704L10.5652 3.50965L10.5654 3.50999L10.5655 3.51035L10.5655 3.51044L10.5655 3.51046L11.0323 4.74061L11.4995 3.51063L11.4995 3.51061L11.4995 3.51054L11.4997 3.51016L11.4998 3.50982L11.5009 3.50721L11.5091 3.48705C11.5171 3.46788 11.5301 3.4376 11.5485 3.39778C11.5851 3.31808 11.6429 3.20067 11.7238 3.05812C11.8861 2.7722 12.1389 2.39019 12.4983 2.00913C13.2118 1.2527 14.3422 0.500244 16.0519 0.500244C17.7549 0.500244 19.1238 1.30744 20.0793 2.47456C21.0416 3.65003 21.5651 5.17046 21.5651 6.52776C21.5651 7.59696 20.9579 8.88464 19.939 10.2647C18.9325 11.628 17.5803 13.0074 16.213 14.247C14.8482 15.4842 13.4813 16.5704 12.4546 17.3478C11.9416 17.7363 11.5144 18.047 11.2159 18.2602Z" stroke="#001254"/>
  </svg>
</template>

<script>
  export default {
    name: 'HeartWhiteIcon'
  }
</script>
