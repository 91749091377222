<template>
	<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M6.95716 6.59384C6.93637 6.73811 6.8706 6.8719 6.76937 6.97668L1.2232 12.7539C1.10005 12.8998 0.922086 12.9885 0.731556 12.999C0.540861 13.0094 0.354359 12.9409 0.215894 12.8094C0.077426 12.678 -0.000591755 12.4952 5.19753e-05 12.3044C0.000696659 12.1134 0.0800052 11.9312 0.21944 11.8006L5.31085 6.5L0.21944 1.19936C0.0800052 1.06879 0.000695705 0.886645 5.19753e-05 0.695629C-0.000592709 0.504773 0.077426 0.321978 0.215894 0.190615C0.354363 0.0590792 0.540865 -0.00943089 0.731556 0.00104618C0.92209 0.0115242 1.10005 0.10018 1.2232 0.246064L6.76937 6.02332C6.91525 6.17517 6.9844 6.38505 6.957 6.59379L6.95716 6.59384Z" fill="black"/>
	</svg>
</template>

<script>
  export default {
    name: 'RightArrowBtn'
  }
</script>
