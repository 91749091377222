<template>
  <v-app id="my-app">
    <div v-if="loading" class="app-loading-container">
      <AppLoading />
    </div>
    <v-main v-else>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import AppLoading from '@/components/AppLoading';

  export default {
    name: 'App',
    components: {
      AppLoading
    },
    computed: {
      ...mapGetters([
        "isUserLoggedIn",
        "userProfile",
        "isNewAddress",
        "selectedAddress",
        "myFavourites"
      ]),
      isAddressSelected() {
        return this.selectedAddress != undefined && this.selectedAddress != null;
      }
    },
    data: () => ({
      loading: true
    }),
    created() {
      console.log('app created');
      
      //Disable double tap zooming on iPhone
      var lastTouchEnd = 0;
      document.addEventListener('touchend', function (event) {
          var now = (new Date()).getTime();
          if (now - lastTouchEnd <= 300) {
              event.preventDefault();
          }
          lastTouchEnd = now;
      }, false);

      let promise_1 = this.getFilters();
      let promise_2 = this.getDishes();
      let promise_3 = this.getChefList();
      let promise_4 = this.getCostCenterInfo();

      let promises = [promise_1, promise_2, promise_3, promise_4];

      Promise.all(promises).then((res) => {
        this.setDeliveryTimeList();
        this.setPickupTimeList();
        if (this.isUserLoggedIn) {
          this.getUserProfile().then(() => {
            let email = this.userProfile.email;
            let payload = {
              customerId: this.userProfile.id,
              email: email
            }

            this.getFavouriteDishes(payload).then(() => {
              let myFavouritesStored = localStorage.getItem("myFavourites") ? JSON.parse(localStorage.getItem("myFavourites")) : [];
              let itemIds = [];
              if (myFavouritesStored.length != 0) {
                if (this.myFavourites.length != 0) {
                  myFavouritesStored.forEach((el) => {
                    let favObj = this.myFavourites.find(object => object.id === el.id);
                    if (favObj === undefined) {
                      itemIds.push(el.id);
                    }
                  });
                } else {
                  itemIds = myFavouritesStored.map((el) => {return el.id});
                }

                if (itemIds.length != 0) {
                  let payload = {
                    customerId: this.userProfile.id,
                    data: {
                      email: this.userProfile.email,
                      itemIds: itemIds,
                      enabled: true
                    }
                  }
                  this.toggleFavouriteDish(payload).then(() => {
                    itemIds.forEach((el) => {
                      this.toggleFavourite({dishId: el, enabled: true});
                    });
                  });
                }
              }
            });

            if (this.isAddressSelected && this.isNewAddress == 'true') {
              let payload = {
                email: email,
                requestData: [this.selectedAddress]
              }

              this.createNewAddress(payload).then(() => {
                this.setIsNewAddress(false);
                this.getAddressList(email).then(() => {
                  this.loading = false;
                });
              });
            } else {
              this.getAddressList(email).then(() => {
                let myOrdersPayload = {
                  customerId: this.userProfile.id,
                  email: email
                }
                this.getMyOrders(myOrdersPayload).then(() => {
                  this.loading = false;
                });
              });
            }
          }).catch(error => {
            if (error.response && error.response.status === 401) {
              this.logout().then(() => {
                if (this.$router.currentRoute.name != 'first_screen') {
                  this.$router.go("/");
                }
              });
            }
          });
        } else {
          if (this.isAddressSelected) {
            this.addAddressToAddressList(this.selectedAddress).then(() => {
              this.loading = false;
            });
          } else {
            this.loading = false;
          }
        }
      });
    },
    methods: {
      ...mapActions([
        "login",
        "logout",
        "getDishes",
        "getChefList",
        "getFilters",
        "getUserProfile",
        "getAddressList",
        "getCostCenterInfo",
        "getMyOrders",
        "addAddressToAddressList",
        "createNewAddress",
        "setIsNewAddress",
        "getFavouriteDishes",
        "resetOrderObjects",
        "resetProfileObjects",
        "resetMyFavourites",
        "toggleFavouriteDish",
        "toggleFavourite",
        "setDeliveryTimeList",
        "setPickupTimeList"
      ]),
    }
  };
</script>

<style type="text/css">
  /* Colors
  green-brand-color: #5BE789
  dark-blue-brand-color: #001254;

  */

  @font-face {
    font-family: "CodecPro";
    src: url("/fonts/Codec-Pro-Regular.otf");
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: "CodecPro";
    src: url("/fonts/Codec-Pro-Bold.otf");
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: "CodecPro";
    src: url("/fonts/Codec-Pro-Heavy.otf");
    font-style: normal;
    font-weight: 700;
  }

  * {
    font-family: "CodecPro";
    color: #001254;
    margin: 0;
    padding: 0;
  }

  html, body {
    width: 100%;
    height: 100%;
  }

  h1 {
    font-size: 48px;
  }

  /* Custom Scrollbar */
  ::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #d9dbe9;
    border-radius: 2px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #9295ad;
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #6e7291;
    border-radius: 2px;
  }
  /* End of Custom Scrollbar */

  #my-app {
    margin: 0;
    padding: 0;

  }

  .app-loading-container {
    height: 100%;
    width: 100%;
  }

  #my-app .v-btn {
    text-transform: initial;
    letter-spacing: 0;
  }

  #my-app .v-slide-group__wrapper {
    touch-action: auto !important;
  }

  #my-app .my-bottom-sheet,
  #my-app .my-bottom-sheet .v-sheet {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }

  #my-app .my-bottom-sheet  {
    max-width: 600px;
  }

  .my-bottom-sheet-content {
    padding: 0 20px 20px;
  }

  .my-page-content {
    margin-top: 50px;
  }

  #my-app .my-text-field {
    margin-bottom: 12px;
  }

  #my-app .my-text-field.v-input input,
  #my-app .my-text-field.v-input .v-text-field__slot label,
  #my-app .my-text-field.v-input input::placeholder {
    color: #001254;
    font-weight: 400;
    font-size: 14px;
  }

  #my-app .v-text-field--filled > .v-input__control > .v-input__slot {
    min-height: unset;
  }

  #my-app.v-application .error--text .v-messages__message {
    color: #ff3505 !important;
    caret-color: #ff3505 !important;
  }

  #my-app .my-divider {
    border-color: #E0E0E0;
  }

  #my-app .my-sheet {
    height: calc(100vh - 124px);
    text-align: center;
  }

  #my-app .full-height {
    height: 100%;
  }

  #my-app .overflow-content {
    padding: 0 20px 150px;
    height: 100%;
    overflow-y: auto;
    overscroll-behavior: none;
  }

  #my-app .select-btn {
    padding: 17px 67px;
    width: 92%;
    height: 52px;
    background-color: #001254;
    border-radius: 44px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
    position: sticky;
    bottom: 30px;
    right: 4%;
  }

  #my-app .add-custom-tip-btn {
    border-radius: 83px;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    padding: 0 30px;
    position: absolute;
    right: 1px;
    bottom: 1px;
  }

  #my-app .signin-btn {
    padding: 17px 34px;
    height: 53px;
    background-color: #001254;
    border-radius: 44px;
    color: #FFFFFF;
  }

  #my-app .register-btn {
    padding: 17px 34px;
    height: 53px;
    color: #001254;
    border-radius: 44px;
    background-color: rgb(242, 242, 242);
  }

  #my-app .select-btn.v-btn--disabled,
  #my-app .order-now-btn.v-btn--disabled,
  #my-app .add-custom-tip-btn.v-btn--disabled,
  #my-app .signin-btn.v-btn.v-btn--disabled,
    #my-app .register-btn.v-btn.v-btn--disabled {
    background-color: #E0E0E0 !important;
    color: #FFFFFF !important;
  }

  #my-app .active-select-btn,
  #my-app .active-select-btn span {
    color: #5BE789;
  }

  #my-app .small-checkbox .v-input--selection-controls__input {
    width: 15px;
    height: 15px;
  }

  #my-app .confirm-btn {
    margin-top: 30px;
    padding: 17px 67px;
    height: 53px;
    background-color: #001254;
    border-radius: 44px;
    color: #FFFFFF;
  }

  #my-app .confirm-btn:disabled {
    background-color: #E0E0E0 !important;
    color: #ffffff !important;
  }

  #my-app .v-list-item--link:before,
  #my-app .v-chip:before {
    background: none;
  }

  #my-app .v-chip--clickable:active {
    box-shadow: none;
  }

  #my-app .v-btn:before {
    background: none;
  }

  #my-app .my-checkbox .v-input--selection-controls__input {
    width: 15px;
    height: 15px;
  }

  .my-link {
    cursor: pointer;
  }

  .main-btn-container {
    position: fixed;
    left: 50%;
    bottom: 30px;
    transform: translate(-50%, 0);
    width: 100%;
    padding: 0 20px;
  }

  @media (min-width: 960px) {
    .main-btn-container {
      width: 900px;
    }
  }

  @media (min-width: 1264px) {
    .main-btn-container {
      width: 1185px;
    }
  }

  @media (min-width: 1904px) {
    .main-btn-container {
      width: 1785px;
    }
  }

  .submit-btn-container {
    position: absolute;
    bottom: 30px;
    left: 20px;
    width: calc(100% - 40px);
  }

  #my-app .order-now-btn {
    height: 52px;
    width: 100%;
    padding: 17px 25px;
    background: #001254;
    border-radius: 44px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
  }

  #my-app .order-now-btn span {
    color: #ffffff;
  }

  .disabled-action {
    opacity: 0.5;
  }

  .my-list-item {
    border-bottom: 1px solid #E0E0E0;
  }

  #my-app .my-list-item.v-list-item--disabled {
    opacity: 0.5;
  }

  #my-app .my-textarea textarea {
    color: #001254;
    font-size: 16px;
    font-weight: normal;
    line-height: 120%;
  }

  #my-app .my-textarea .v-input__slot {
    background-color: rgba(242, 242, 242, 0.5);
    border-radius: 9px;
  }

  #my-app .my-textarea .v-input__slot:before,
  #my-app .my-textarea .v-input__slot:after {
    display: none;
  }

  .chef-review-container {
    display: flex;
    align-items: center;
    line-height: 0;
  }

  #my-app .checkout-quantity-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #5BE789;
    border-radius: 78px;
    padding: 9px 15px;
    min-height: 54px;
    min-width: 54px;
    max-height: 54px;
    max-width: 54px;
  }

  #my-app .bar-btn-icon,
  #my-app .bar-btn-icon svg {
    width: 62px;
    height: 7px;
  }

  #my-app .dish-tag {
    padding: 5px 10px;
    min-width: 26px;
    margin-right: 8px;
    border-radius: 30px;
    height: 19px;
    line-height: 1 !important;
  }

  #my-app .autocomplete-input .v-input__control .v-text-field__slot input::placeholder {
    color: transparent !important;
  }

  #my-app .v-overlay__scrim {
    border-radius: 0;
  }

  .confirm-btn-container {
    position: sticky;
    left: 0;
    bottom: 30px;
    width: 100%;
  }

  #my-app .explore-dishes-btn {
    height: 52px;
    width: 100%;
    padding: 17px 67px;
    background: #001254;
    border-radius: 44px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-transform: capitalize;
  }

  #my-app .title-0 {
    font-size: 64px;
    font-weight: 700;
    line-height: 100%;
  }

  #my-app .title-1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 108%;
  }

  #my-app .title-2 {
    font-size: 28px;
    font-weight: 500;
    line-height: 150%;
  }

  #my-app .title-3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
  }

  #my-app .title-4 {
    font-size: 21px;
    font-weight: 700;
    line-height: 120%;
  }

  #my-app .title-5 {
    font-size: 21px;
    font-weight: 500;
    line-height: 120%;
  }

  #my-app .title-6 {
    font-size: 21px;
    font-weight: normal;
    line-height: 120%;
  }

  #my-app .title-7 {
    font-size: 18px;
    font-weight: 700;
    line-height: 120%;
  }

  #my-app .title-8 {
    font-size: 18px;
    font-weight: 500;
    line-height: 120%;
  }

  #my-app .title-9 {
    font-size: 18px;
    font-weight: normal;
    line-height: 120%;
  }

  #my-app .title-10 {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
  }

  #my-app .title-11 {
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
  }

  #my-app .title-12 {
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
  }

  #my-app .title-13 {
    font-size: 16px;
    font-weight: normal;
    line-height: 120%;
  }

  #my-app .title-14 {
    font-size: 14px;
    font-weight: normal;
    line-height: 120%;
  }

  #my-app .title-15 {
    font-size: 12px;
    font-weight: 700;
    line-height: 120%;
  }

  #my-app .small-text {
    font-size: 12px;
    font-weight: normal;
    line-height: 120%;
  }

  #my-app .body-1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
  }

  #my-app .body-3 {
    font-size: 12px;
    font-weight: normal;
    line-height: 13px;
    color: #001254;
    text-align: left;
  }

  .error-message {
    font-size: 12px;
    font-weight: normal;
    line-height: 13px;
    color: #FF6700;
    text-align: center;
    margin-bottom: 8px;
  }

  .address-limitation {
    padding: 8px 20px;
    background-color: #FF6700;
    border-radius: 44px;
    color: #ffffff;
    font-size: 14px;
    font-weight: normal;
    line-height: 15px;
    text-align: center;
    margin-bottom: 8px;
  }
</style>