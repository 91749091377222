<template>
	<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" y="0.5" width="14" height="14" rx="3.5" stroke="#001254"/>
		<rect x="3" y="3" width="9" height="9" rx="2" fill="#5BE789"/>
	</svg>
</template>

<script>
  export default {
    name: 'CheckboxOnIcon'
  }
</script>
