<template>
	<svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M26.5226 28.9012C26.5226 30.7566 26.0426 31.1735 24.5576 30.7434C23.0245 30.3002 21.658 29.4667 20.2944 28.6399C18.9311 27.813 17.6349 26.9333 16.3483 26.0137V26.014C16.1532 25.831 15.8554 25.831 15.6602 26.014C13.6198 27.5117 11.4893 28.8738 9.28158 30.0919C8.56587 30.5049 7.79296 30.8016 6.99015 30.9718C6.32445 31.0876 6.00115 30.8527 5.87639 30.1615C5.6748 29.0172 5.90519 27.9027 6.10053 26.7914L6.10031 26.7912C6.50148 24.6261 7.03593 22.4895 7.70054 20.395C7.78225 20.2236 7.79431 20.0256 7.73425 19.845C7.6742 19.6644 7.54695 19.516 7.38063 19.4327C5.28432 17.8882 3.20075 16.327 1.37974 14.4186C0.914046 13.9577 0.51801 13.4271 0.205221 12.8443C-0.175641 12.0837 -0.0347688 11.6008 0.698152 11.1907H0.697928C1.54605 10.777 2.45647 10.5163 3.38964 10.4201C5.75296 10.093 8.13589 9.94047 10.5204 9.96377C10.6506 9.98846 10.7845 9.95662 10.8912 9.87587C10.9977 9.79512 11.0674 9.67262 11.0839 9.53697C11.7239 7.11597 12.364 4.70166 13.5003 2.46216H13.5001C13.8379 1.70755 14.3214 1.03225 14.921 0.477856C15.7116 -0.183558 16.4445 -0.160525 17.1966 0.560446C18.0094 1.34092 18.5024 2.33987 18.9535 3.35517H18.9537C19.8059 5.34455 20.4841 7.40888 20.9795 9.52341C20.9927 9.66921 21.0641 9.80256 21.1764 9.89184C21.2889 9.98089 21.432 10.0176 21.5718 9.99312C24.1322 10.0427 26.6926 10.1055 29.2209 10.5256C29.8875 10.6174 30.5418 10.7895 31.1699 11.0382C32.0725 11.4219 32.2133 11.8683 31.717 12.7283L31.7173 12.7281C31.3994 13.2502 31.0245 13.7331 30.6001 14.167C28.7757 16.1249 26.6859 17.762 24.609 19.4093L24.6092 19.409C24.3846 19.541 24.2966 19.8296 24.4074 20.0705C25.1914 22.3779 25.8254 24.7369 26.3054 27.1317C26.4193 27.7151 26.4921 28.3067 26.5229 28.9009L26.5226 28.9012Z" fill="#E0E0E0"/>
	</svg>
</template>

<script>
  export default {
    name: 'EmptyStarIcon'
  }
</script>
