import axios from "axios";
import moment from "moment";

// let selectedOrderReceiveTimeInit = {
//   'delivery': {id: 1, icon: 'now_icon', title: 'Now', small_title: 'Now'},
//   'pickup': {id: 1, icon: 'now_icon', title: 'Now', small_title: 'Now'}
// };

let selectedOrderReceiveTimeInit = {
  'delivery': {id: 2, icon: 'later_icon', title: 'Select Time Slot', small_title: 'Select Time'},
  'pickup': {id: 2, icon: 'later_icon', title: 'Select Time Slot', small_title: 'Select Time', subtitle: ''}
};

let selectedOrderReceiveTimeNowInactive = {
  'delivery': {id: 2, icon: 'later_icon', title: 'Select Time Slot', small_title: 'Select Time'},
  'pickup': {id: 2, icon: 'later_icon', title: 'Select Time Slot', small_title: 'Select Time', subtitle: ''}
};

export default {
  state: {
    myOrders: [],
    cart: JSON.parse(localStorage.getItem('cart')) || [],
    orderTotal: parseFloat(localStorage.getItem('orderTotal'), 2) || 0,
    orderComment: localStorage.getItem("orderComment") || '',
    deliveryComment: localStorage.getItem("deliveryComment") || '',
    orderDetails: JSON.parse(localStorage.getItem('orderDetails')) || {},
    orderResponse: null,
    orderReceiveMethod: localStorage.getItem('orderReceiveMethod') || 'delivery',
    deliveryTimeMenu: [
      // {id: 1, icon: 'now_icon', title: 'Now', small_title: 'Now'},
      {id: 2, icon: 'later_icon', title: 'Select Time Slot', small_title: 'Select Time'}
    ],
    pickupTimeMenu: [
      // {id: 1, icon: 'now_icon', title: 'Now', small_title: 'Now', subtitle: 'ready in 20 min'},
      {id: 2, icon: 'later_icon', title: 'Select Time Slot', small_title: 'Select Time', subtitle: ''}
    ],
    selectedOrderReceiveTime: JSON.parse(localStorage.getItem('selectedOrderReceiveTime')) || selectedOrderReceiveTimeInit,
    tips: ['0.00', '0.50', '1.00', '2.00', 'other'],
    selectedTip: null,
    containersList: [
      {id: 1, name: 'Single use', title: 'Single use 100% compostable', subtitle: ''},
      {id: 2, name: 'Reusable', title: 'Reusable IKEA glass food container', subtitle: 'Help us to be sustainable, simply return it to us with your next order!'},
    ],
    foodContainer: null,
    workingHours: JSON.parse(localStorage.getItem('workingHours')) || null,
    deliveryTimeList: JSON.parse(localStorage.getItem('deliveryTimeList')) || [],
    pickupTimeList: JSON.parse(localStorage.getItem('pickupTimeList')) || [],
    laterDates: [
      {
        id: 1,
        title: 'Today',
        formattedDate: moment().format("D MMM"),
        date: moment(),
        active: false,
        disabled: false
      },
      {
        id: 2,
        title: 'Tomorrow',
        formattedDate: moment().add(1, 'days').format("D MMM"),
        date: moment().add(1, 'days'),
        active: false,
        disabled: true
      },
      {
        id: 3,
        title: moment().add(2, 'days').format("ddd"),
        formattedDate: moment().add(2, 'days').format("D MMM"),
        date: moment().add(3, 'days'),
        active: false,
        disabled: true
      }
    ],
    selectedLaterDate: JSON.parse(localStorage.getItem("selectedLaterDate")) || {},
    selectedDeliveryLaterTime: parseInt(localStorage.getItem("selectedDeliveryLaterTime")) || 0,
    selectedPickupLaterTime: parseInt(localStorage.getItem("selectedPickupLaterTime")) || 0,
    couponCode: localStorage.getItem("couponCode") || "",
    isNowActive: true,
    isClosed: false,
    minutesUntilDelivery: 0,
    minutesUntilDeliveryText: '',
    minutesUntilTakeAway: 0,
    minutesUntilTakeAwayText: ''
  },
  getters: {
    myOrders: state => state.myOrders,
    cart: state => state.cart,
    orderTotal: state => state.orderTotal,
    orderComment: state => state.orderComment,
    deliveryComment: state => state.deliveryComment,
    orderDetails: state => state.orderDetails,
    orderResponse: state => state.orderResponse,
    orderReceiveMethod: state => state.orderReceiveMethod,
    deliveryTimeMenu: state => state.deliveryTimeMenu,
    pickupTimeMenu: state => state.pickupTimeMenu,
    selectedOrderReceiveTime: state => state.selectedOrderReceiveTime,
    tips: state => state.tips,
    selectedTip: state => state.selectedTip,
    containersList: state => state.containersList,
    foodContainer: state => state.foodContainer,
    workingHours: state => state.workingHours,
    deliveryTimeList: state => state.deliveryTimeList,
    pickupTimeList: state => state.pickupTimeList,
    laterDates: state => state.laterDates,
    selectedLaterDate: state => state.selectedLaterDate,
    selectedDeliveryLaterTime: state => state.selectedDeliveryLaterTime,
    selectedPickupLaterTime: state => state.selectedPickupLaterTime,
    couponCode: state => state.couponCode,
    isNowActive: state => state.isNowActive,
    isClosed: state => state.isClosed,
    minutesUntilDelivery: state => state.minutesUntilDelivery,
    minutesUntilDeliveryText: state => state.minutesUntilDeliveryText,
    minutesUntilTakeAway: state => state.minutesUntilTakeAway,
    minutesUntilTakeAwayText: state => state.minutesUntilTakeAwayText
  },
  mutations: {
    setMyOrders(state, myOrdersObj) {
      state.myOrders = myOrdersObj;
    },
    setOrderDetails(state, ordersObj) {
      state.orderDetails = ordersObj;
      localStorage.setItem("orderDetails", JSON.stringify(ordersObj));
    },
    setOrderResponse(state, ordersObj) {
      state.orderResponse = ordersObj;
    },
    setOrderTotal(state, total) {
      state.orderTotal = total;
      localStorage.setItem("orderTotal", total);
    },
    setOrderComment(state, comment) {
      state.orderComment = comment;
      localStorage.setItem("orderComment", comment)
    },
    setDeliveryComment(state, comment) {
      state.deliveryComment = comment;
      localStorage.setItem("deliveryComment", comment)
    },
    setOrderReceiveMethod(state, method) {
      state.orderReceiveMethod = method;
      localStorage.setItem("orderReceiveMethod", method);
    },
    setSelectedOrderReceiveTime(state, timeObj) {
      state.selectedOrderReceiveTime = timeObj;
      localStorage.setItem("selectedOrderReceiveTime", JSON.stringify(state.selectedOrderReceiveTime));
    },
    setSelectedOrderReceiveTimeProp(state, payload) {
      let prop = payload.prop;
      let value = payload.value;

      state.selectedOrderReceiveTime[prop] = value;
      localStorage.setItem("selectedOrderReceiveTime", JSON.stringify(state.selectedOrderReceiveTime));
    },
    setSelectedTip(state, tipIndex) {
      state.selectedTip = tipIndex;
    },
    setFoodContainer(state, container) {
      state.foodContainer = container;
    },
    setWorkingHours(state, workingHoursObj) {
      state.workingHours = workingHoursObj;
    },
    setSelectedLaterDate(state, dateObj) {
      state.selectedLaterDate = dateObj;
      localStorage.setItem("selectedLaterDate", JSON.stringify(dateObj));
    },
    setSelectedDeliveryLaterTime(state, timeIndex) {
      state.selectedDeliveryLaterTime = timeIndex;
      localStorage.setItem("selectedDeliveryLaterTime", timeIndex);
    },
    setSelectedPickupLaterTime(state, timeIndex) {
      state.selectedPickupLaterTime = timeIndex;
      localStorage.setItem("selectedPickupLaterTime", timeIndex);
    },
    setCouponCode(state, code) {
      state.couponCode = code;
      localStorage.setItem("couponCode", code);
    },
    setIsNowActive(state, value) {
      state.isNowActive = value;
    },
    setIsClosed(state, value) {
      state.isClosed = value;
    },
    setMinutesUntilDelivery(state, value) {
      state.minutesUntilDelivery = value;
    },
    setMinutesUntilDeliveryText(state, value) {
      state.minutesUntilDeliveryText = value;
    },
    setMinutesUntilTakeAway(state, value) {
      state.minutesUntilTakeAway = value;
    },
    setMinutesUntilTakeAwayText(state, value) {
      state.minutesUntilTakeAwayText = value;
    },
    addToCart(state, dish) {
      state.cart.push(dish);
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    addCustomTip(state, value) {
      let amount = parseFloat(value, 2);
      state.tips.splice(4, 1, amount.toFixed(2));
    },
    resetTip(state) {
      state.tips = ['0.00', '0.50', '1.00', '2.00', 'other'];
      state.selectedTip = null;
    },
    removeFromCart(state, dish) {
      state.cart = state.cart.filter((el) => {
        return el.id != dish.id;
      });
    },
    increaseDishQuantity(state, orderItemId) {
      let matchedDish = state.cart.find(el => el.orderItemId == orderItemId);
      if (matchedDish) {
        matchedDish.quantity += 1;
      }
      localStorage.removeItem("cart");
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    decreaseDishQuantity(state, orderItemId) {
      let matchedDish = state.cart.find(el => el.orderItemId == orderItemId);
      if (matchedDish && matchedDish.quantity != 1) {
        matchedDish.quantity -= 1;
      } else if (matchedDish && matchedDish.quantity == 1) {
        state.cart = state.cart.filter((el) => {
          return el.orderItemId != orderItemId;
        });
      }
      localStorage.removeItem("cart");
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    setDeliveryTimeList(state) {
      state.deliveryTimeList = [];
      let hoursObj = null;

      if (state.selectedLaterDate.title == 'Today') {
        let today = moment().day();
        hoursObj = state.workingHours.find(el => el.dayOfTheWeek == today);
      } else {
        let tomorrow = moment().add(1, 'd').day();
        hoursObj = state.workingHours.find(el => el.dayOfTheWeek == tomorrow);
      }

      if (hoursObj?.timeRanges != undefined && hoursObj.timeRanges.length != 0) {
        let start_time = hoursObj.timeRanges[0].start;
        let end_time = hoursObj.timeRanges[0].end;
        let startTime = start_time.split(":");
        let startMoment = moment({hour: startTime[0], minutes: startTime[1]}).add(1,'h').add(30,'m');
        let startHour = startMoment.hour();
        let startMinutes = startMoment.minutes();

        if (state.selectedLaterDate.title == 'Today') {
          let nowHour = moment().hour();
          let nowMinutes = moment().minutes();
          if (nowHour >= startHour && nowHour < 16) {
            if (nowMinutes <= 45) {
              startHour = nowHour + 1;
              startMinutes = 0;
            } else {
              startHour = nowHour + 2;
              startMinutes = 0;
            }
          } 
          else if (nowHour >= startHour && nowHour >= 16) {
            if (nowMinutes <= 15) {
              startHour = nowHour;
              startMinutes = 30;
            } else if (nowMinutes > 15 && nowMinutes <= 45) {
              startHour = nowHour + 1;
              startMinutes = 0;
            } else if (nowMinutes > 45) {
              startHour = nowHour + 1;
              startMinutes = 30;
            }
          }
        } 

        let endTime = end_time.split(":");
        let endHour = endTime[0];
        let endMinutes = endTime[1];

        let start = moment({hour: startHour, minute: startMinutes});
        let end = moment({hour: endHour, minute: endMinutes});

        if (state.selectedLaterDate.id == 1 && start.isAfter(end)) {
          state.laterDates[0].disabled = true;
          state.laterDates[0].active = false;
          // state.selectedLaterDate = {id:2, title: 'Tomorrow', formattedDate: moment().add(1, 'days').format("D MMM"), date: moment().add(1, 'days'), active: true, disabled: false};

          startHour = startTime[0];
          startMinutes = startTime[1];
          start = moment({hour: startHour, minute: startMinutes});
        } else if (state.selectedLaterDate.id == 1 && !start.isAfter(end)) {
          state.laterDates[0].disabled = false;
          state.laterDates[0].active = true;
        }

        // Set delivery time list
        while (start.isBefore(end)) {
          let nextTimeSlot = moment(start).add(1, 'h');
          if (start.hour() >= 17) {
            nextTimeSlot = moment(start).add(30, 'm');
          }
          let intervalStart = start.format("HH:mm");
          let intervalEnd = nextTimeSlot.format("HH:mm");

          state.deliveryTimeList.push(intervalStart + ' - ' + intervalEnd);
          start = nextTimeSlot;
        }
        localStorage.setItem("deliveryTimeList", JSON.stringify(state.deliveryTimeList));
      } 
    },
    setPickupTimeList(state) {
      state.pickupTimeList = [];
      let hoursObj = null;

      if (state.selectedLaterDate.title == 'Today') {
        let today = moment().day();
        hoursObj = state.workingHours.find(el => el.dayOfTheWeek == today);
      } else {
        let tomorrow = moment().add(1, 'd').day();
        hoursObj = state.workingHours.find(el => el.dayOfTheWeek == tomorrow);
      }

      if (hoursObj?.timeRanges != undefined && hoursObj.timeRanges.length != 0) {
        let start_time = hoursObj.timeRanges[0].start;
        let end_time = hoursObj.timeRanges[0].end;
        let startTime = start_time.split(":");
        let startMoment = moment({hour: startTime[0], minutes: startTime[1]}).add(1,'h').add(30,'m');
        let startHour = startMoment.hour();
        let startMinutes = startMoment.minutes();
        

        if (state.selectedLaterDate.title == 'Today') {
          let nowHour = moment().hour();
          let nowMinutes = moment().minutes();
          if (nowHour >= startHour) {
            if (nowMinutes <= 15) {
              startHour = nowHour;
              startMinutes = 30;
            } else if (nowMinutes > 15 && nowMinutes <= 45) {
              startHour = nowHour + 1;
              startMinutes = 0;
            } else if (nowMinutes > 45) {
              startHour = nowHour + 1;
              startMinutes = 30;
            }
          }
        }

        let endTime = end_time.split(":");
        let endHour = endTime[0];
        let endMinutes = endTime[1];

        let start = moment({hour: startHour, minute: startMinutes});
        let end = moment({hour: endHour, minute: endMinutes});

        if (state.selectedLaterDate.id == 1 && start.isAfter(end)) {
          state.laterDates[0].disabled = true;
          state.laterDates[0].active = false;
          // state.selectedLaterDate = {id:2, title: 'Tomorrow', formattedDate: moment().add(1, 'days').format("D MMM"), date: moment().add(1, 'days'), active: true, disabled: false};

          startHour = startTime[0];
          startMinutes = startTime[1];
          start = moment({hour: startHour, minute: startMinutes});
        } else if (state.selectedLaterDate.id == 1 && !start.isAfter(end)) {
          state.laterDates[0].disabled = false;
          state.laterDates[0].active = true;
        }

        // Set pickup time list
        while (start.isBefore(end)) {
          let nextTimeSlot = moment(start).add(30, 'm');
          let intervalStart = start.format("HH:mm");
          let intervalEnd = nextTimeSlot.format("HH:mm");

          state.pickupTimeList.push(intervalStart + ' - ' + intervalEnd);
          start = nextTimeSlot;
        }
        localStorage.setItem("pickupTimeList", JSON.stringify(state.pickupTimeList));
      } 
    },
    resetSelectedLaterDate(state) {
      state.selectedLaterDate = null;
      localStorage.removeItem("selectedLaterDate");
    },
    resetOrderObjects(state) {
      state.cart = [];
      state.orderTotal = 0;
      state.orderComment = '';
      state.selectedDeliveryLaterTime = 0;
      state.selectedPickupLaterTime = 0;
      state.selectedLaterDate = {};
      localStorage.removeItem("cart");
      localStorage.removeItem("orderTotal");
      localStorage.removeItem("orderComment");
      localStorage.removeItem("selectedDeliveryLaterTime");
      localStorage.removeItem("selectedPickupLaterTime");
      localStorage.removeItem("selectedLaterDate");
    }
  },
  actions: {
    createNewOrder({commit}, payload) {
      return new Promise((resolve, reject) => {
        let url = `${process.env.VUE_APP_BASE_URL}/accounts/We%20Cook/costcenter/wecook/orders/nocapcha/delivery`;
        axios.post(url, payload).then(res => {
          resolve(res.data);
        }).catch(er => {
          let errorMessage = er;
          if(er?.response != undefined && er.response?.data != undefined && er.response.data?.message != undefined) {
            errorMessage = er.response.data.message
          }
          reject(errorMessage);
        });
      });
    },
    getCostCenterInfo({commit}) {
      return new Promise((resolve, reject) => {
        let url = `${process.env.VUE_APP_BASE_URL}/api/wecook/info`;
        axios.get(url, {}).then(res => {
          let workingHours = res.data.settings.customerPermissions.registered.services.delivery.workingHours;
          let today = moment().isoWeekday();
          let hoursObj = workingHours.find(el => el.dayOfTheWeek == today);
          
          let isNowActive = true;
          let isClosed = false;
          if (hoursObj?.timeRanges != undefined && hoursObj.timeRanges.length == 0) {
            isNowActive = false;
            isClosed = true;
            commit('setSelectedOrderReceiveTime', selectedOrderReceiveTimeNowInactive);
          } else if (hoursObj?.timeRanges != undefined && hoursObj.timeRanges.length != 0) {
            let startHour = parseInt(hoursObj.timeRanges[0].start.split(":")[0]);
            let nowHour = moment().hour();
            if (nowHour < startHour) {
              isNowActive = false;
              commit('setSelectedOrderReceiveTime', selectedOrderReceiveTimeNowInactive);
            }

            let startMinutes = parseInt(hoursObj.timeRanges[0].start.split(":")[1]);
            let startTime = moment({hour: startHour, minute: startMinutes});

            let endHour = parseInt(hoursObj.timeRanges[0].end.split(":")[0]);
            let endMinutes = parseInt(hoursObj.timeRanges[0].end.split(":")[1]);
            let endTime = moment({hour: endHour, minute: endMinutes});
            let nowTime = moment();

            if ((nowTime < startTime) || (nowTime > endTime)) {
              isClosed = true;
            }
          }
          commit('setIsClosed', isClosed);
          commit('setIsNowActive', isNowActive);
          commit('setWorkingHours', workingHours);
          commit('setMinutesUntilDelivery', res.data.settings.minutesUntilDelivery);
          commit('setMinutesUntilDeliveryText', res.data.settings.minutesUntilDeliveryText);
          commit('setMinutesUntilTakeAway', res.data.settings.minutesUntilTakeAway);
          commit('setMinutesUntilTakeAwayText', res.data.settings.minutesUntilTakeAwayText);
          resolve();
        }).catch(er => {
          console.log(er);
          reject();
        });
      })
    },
    addToCart({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('addToCart', payload);
        resolve();
      })
    },
    getMyOrders({commit}, payload) {
      let orderStatus = [
        'pending', //0, old value: 'new'
        'confirmed', //1
        'canceled', //2
        'pending local approval', //3
        'temporary', //4
        'to be paid online', //5
        'online invalid' //6
      ];

      return new Promise((resolve, reject) => {
        let customerId = payload.customerId;
        let email = payload.email;
        let url = `${process.env.VUE_APP_BASE_URL}/accounts/wecook/costCenter/${process.env.VUE_APP_COST_CENTER_ID}/customer/${customerId}/orders`;
        axios.get(url, {params: {email: email}}).then(res => {
          let myOrders = res.data.content.map((orderItem) => {
            if (orderItem.items != null) {
              let dishes = orderItem.items.map((dishItem) => {
                let quantity = dishItem.basePrices[0].quantity;
                let itemPrice = dishItem.basePrices[0].price / 100;
                let dishObj = {
                  id: dishItem.id,
                  title: dishItem.slang,
                  price: itemPrice,
                  quantity: quantity,
                  totalItemPrice: dishItem.basePrices[0].price / 100
                }
                return dishObj;
              });

              let orderDate = moment(orderItem.creationDate).format("ddd D MMM YYYY, HH:mm");

              let orderObj = {
                id: orderItem.id,
                referenceId: orderItem.referenceId,
                comments: orderItem.comments,
                customHour: orderItem.customHour,
                paymentMethodType: orderItem.paymentMethodType,
                creationDate: orderItem.creationDate,
                address: orderItem.address,
                order_date: orderDate,
                order_status: orderStatus[orderItem.status],
                order_total: orderItem.orderTotal / 100,
                receptionType: orderItem.receptionType,
                rider_tip: 0.0,
                dishes: dishes
              }
              return orderObj;
            } else {
              return {
                id: orderItem.id,
                referenceId: orderItem.referenceId,
                comments: orderItem.comments,
                customHour: orderItem.customHour,
                paymentMethodType: orderItem.paymentMethodType,
                creationDate: orderItem.creationDate,
                address: orderItem.address,
                order_date: orderDate,
                order_status: orderStatus[orderItem.status],
                order_total: orderItem.orderTotal / 100,
                receptionType: orderItem.receptionType,
                rider_tip: 0.0,
                dishes: []
              }
            }
          });

          myOrders = myOrders.sort((a, b) => {
            return b.creationDate - a.creationDate;
          });

          commit('setMyOrders', myOrders);
          resolve();
        }).catch(er => {
          console.log(er);
          reject();
        });
      })
    },
    setOrderDetails({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setOrderDetails', payload);
        resolve();
      })
    },
    setOrderResponse({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setOrderResponse', payload);
        resolve();
      })
    },
    setOrderReceiveMethod({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setOrderReceiveMethod', payload);
        resolve();
      })
    },
    setOrderReceiveTime({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setOrderReceiveTime', payload);
        resolve();
      })
    },
    increaseDishQuantity({commit}, dishId) {
      return new Promise((resolve, reject) => {
        commit('increaseDishQuantity', dishId);
        resolve();
      })
    },
    decreaseDishQuantity({commit}, dishId) {
      return new Promise((resolve, reject) => {
        commit('decreaseDishQuantity', dishId);
        resolve();
      })
    },
    setOrderTotal({commit}, total) {
      return new Promise((resolve, reject) => {
        commit('setOrderTotal', total);
        resolve();
      })
    },
    addCustomTip({commit}, customTip) {
      return new Promise((resolve, reject) => {
        commit('addCustomTip', customTip);
        resolve();
      })
    },
    resetTip({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetTip');
        resolve();
      })
    },
    setDeliveryTimeList({commit}) {
      return new Promise((resolve, reject) => {
        commit('setDeliveryTimeList');
        resolve();
      })
    },
    setPickupTimeList({commit}) {
      return new Promise((resolve, reject) => {
        commit('setPickupTimeList');
        resolve();
      })
    },
    setSelectedLaterDate({commit}, dateObj) {
      return new Promise((resolve, reject) => {
        commit('setSelectedLaterDate', dateObj);
        resolve();
      })
    },
    setSelectedDeliveryLaterTime({commit}, time) {
      return new Promise((resolve, reject) => {
        commit('setSelectedDeliveryLaterTime', time);
        resolve();
      })
    },
    setSelectedPickupLaterTime({commit}, time) {
      return new Promise((resolve, reject) => {
        commit('setSelectedPickupLaterTime', time);
        resolve();
      })
    },
    resetSelectedLaterDate({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetSelectedLaterDate');
        resolve();
      })
    },
    resetOrderObjects({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetOrderObjects');
        resolve();
      })
    },
  }
}
