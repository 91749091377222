<template>
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M26.4545 0H5.10445C2.33154 0 0.0673828 2.26449 0.0673828 5.03706V20.8879C0.0673828 23.6608 2.33187 25.925 5.10445 25.925H8.9862C9.44845 25.925 9.81786 26.2947 9.81786 26.7566V31.0082C9.81786 31.5626 10.3262 31.9323 10.8807 31.7937C12.1285 31.4701 14.3005 30.2687 16.2414 26.3869C16.38 26.1097 16.6572 25.9246 16.9808 25.9246H26.5468C29.3197 25.9246 31.5839 23.6601 31.5839 20.8876V5.03755C31.4913 2.26497 29.2271 0.000486676 26.4543 0.000486676L26.4545 0Z" fill="#001254"/>
	</svg>
</template>

<script>
  export default {
    name: 'CommentIcon'
  }
</script>
