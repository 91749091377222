import axios from "axios";

let dishDetailsTemp = {
  id: null,
  chefId:null,
  chefName: '',
  chefReview: '',
  chefAvatar: '',
  dishImage: '',
  price: '',
  title: '',
  description: '',
  tags: [], 
  modifiers: [],
  ingredients: [],
  selectedModifiers: [],
  favourite: false,
  quantity: 1,
  totalItemPrice: 0
}

export default {
  state: {
    originalDishList: [],
    allDishes: [],
    dishList: [],
    myFavourites: JSON.parse(localStorage.getItem('myFavourites')) || [],
    dishDetails: dishDetailsTemp
  },
  getters: {
    originalDishList: state => state.originalDishList,
    allDishes: state => state.allDishes,
    dishList: state => state.dishList,
    myFavourites: state => state.myFavourites,
    dishDetails: state => state.dishDetails
  },
  mutations: {
    setOriginalDishList(state, dishesObj) {
      state.originalDishList = dishesObj;
    },
    setAllDishes(state, dishesObj) {
      state.allDishes = dishesObj;
    },
    setDishes(state, dishesObj) {
      state.dishList = dishesObj;
    },
    setMyFavourites(state, dishesObj) {
      state.myFavourites = [];
      state.myFavourites = dishesObj;
    },
    setDishDetails(state, dishObj) {
      state.dishDetails = dishObj;
    },
    toggleFavourite(state, dishObj) {
      let dishId = dishObj.dishId;
      let enabled = dishObj.enabled;

      let dish = state.dishList.find(el => el.id === dishId);
      if (dish != undefined) {
        dish.favourite = enabled;

        let dishAlreadyInFavourite = state.myFavourites.find(el => el.id === dishId);

        if (dish.favourite && dishAlreadyInFavourite === undefined) {
          state.myFavourites.push(dish);
        } else if (!dish.favourite) {
          state.myFavourites = state.myFavourites.filter((el) => {
            return el.id != dishId;
          });
        }
      }
      
      if (state.myFavourites.length != 0) {
        localStorage.removeItem('myFavourites');
        localStorage.setItem('myFavourites', JSON.stringify(state.myFavourites));
      } else {
        localStorage.removeItem('myFavourites');
      }
    },
    updateDishDetails(state, payload) {
      let prop = payload.prop;
      let value = payload.value;
      state.dishDetails[prop] = value;
    },
    setSelectedModifiers(state, payload) {
      state.dishDetails.selectedModifiers = payload;
    },
    setSelectedIngredient(state, index) {
      state.dishDetails.selectedIngredient = index;
    },
    resetMyFavourites(state) {
      state.myFavourites = [];
      state.allDishes.forEach((el) => {el.favourite = false});
      state.dishList.forEach((el) => {el.favourite = false});
      localStorage.removeItem('myFavourites');
    }
  },
  actions: {
    getDishes({commit, state}) {
      return new Promise((resolve, reject) => {
        let url = `${process.env.VUE_APP_BASE_URL}/wecook/dish/list/${process.env.VUE_APP_ACCOUNT_ID}/chef/enabled`;
        axios.get(url, {}).then(res => {
          this.commit('setOriginalDishList', res.data.content);
          let response = res.data.content.map((el) => {
            let price = el.basePrices[0].price / 100;
            let modifiers = [];
            let ingredients = [];

            if (el.modifierGroups.length != 0) {
              el.modifierGroups.forEach((modGroup) => {
                for (let list of modGroup.group.modifiers) {
                  let type = modGroup.group.modifiers[0].type;
                  if (type == 0 && list.values) {
                    modifiers = list.values;
                  } else if (type == 3 && list.values) {
                    ingredients = list.values;
                  }
                }
              });
            }

            let chefAvatar = el.chefAvatar.url.replace(" ", "%20");
            let dishImage = el.dishImage == null ? '' : el.dishImage.url.replace(" ", "%20");
            let favourite = false;

            if (state.myFavourites.length != 0) {
              let dishExists = state.myFavourites.find(element => element.id == el.id);
              if (dishExists) {
                favourite = true;
              }
            }

            return {
              id: el.id,
              chefId: el.chefId,
              chefName: el.chefName.en,
              chefReview: el.chefReview,
              chefAvatar: chefAvatar,
              dishImage: dishImage,
              price: price,
              title: el.content.en.title,
              description: el.content.en.description,
              tags: el.tags,
              modifiers: modifiers,
              ingredients: ingredients,
              selectedModifiers: [],
              selectedIngredient: 0,
              favourite: favourite,
              quantity: 1,
              totalItemPrice: 0
            }
          });

          commit('setAllDishes', response);
          commit('setDishes', response);
          resolve();
        }).catch(er => {
          console.log(er);
          reject();
        });
      })
    },
    getFavouriteDishes({commit, state}, payload) {
      return new Promise((resolve, reject) => {
        let customerId = payload.customerId;
        let email = payload.email;
        let url = `${process.env.VUE_APP_BASE_URL}/wecook/dish/We%20Cook/list/costcenter/${process.env.VUE_APP_COST_CENTER_ID}/customer/${customerId}/get/favourites`;
        axios.get(url, {params: {email: email}}).then(res => {
          let response = res.data.content.map((el) => {
            let dish = state.dishList.find(item => item.id === el.id);
            dish.favourite = true;

            let price = el.basePrices[0].price / 100;
            let modifiers = [];
            let ingredients = [];

            if (el.modifierGroups.length != 0) {
              el.modifierGroups.forEach((modGroup) => {
                for (let list of modGroup.group.modifiers) {
                  let type = modGroup.group.modifiers[0].type;
                  if (type == 0 && list.values) {
                    modifiers = list.values;
                  } else if (type == 3 && list.values) {
                    ingredients = list.values;
                  }
                }
              });
            }

            let chefAvatar = el.chefAvatar.url.replace(" ", "%20");
            let dishImage = el.dishImage == null ? '' : el.dishImage.url.replace(" ", "%20");

            return {
              id: el.id,
              chefId: el.chefId,
              chefName: el.chefName.en,
              chefReview: el.chefReview,
              chefAvatar: chefAvatar,
              dishImage: dishImage,
              price: price,
              title: el.content.en.title,
              description: el.content.en.description,
              tags: el.tags,
              modifiers: modifiers,
              ingredients: ingredients,
              selectedModifiers: [],
              selectedIngredient: 0,
              favourite: true,
              quantity: 1,
              totalItemPrice: 0
            }
          });
          commit('setMyFavourites', response);
          resolve();
        }).catch(er => {
          console.log(er);
          reject();
        });
      })
    },
    toggleFavouriteDish({commit}, payload) {
      return new Promise((resolve, reject) => {
        let customerId = payload.customerId;
        let data = payload.data;
        let url = `${process.env.VUE_APP_BASE_URL}/wecook/dish/We%20Cook/costcenter/${process.env.VUE_APP_COST_CENTER_ID}/customer/${customerId}/favourite/add`;
        axios.post(url, data).then(res => {
          resolve();
        }).catch(er => {
          let errorMessage = er;
          if(er?.response != undefined && er.response?.data != undefined && er.response.data?.message != undefined) {
            errorMessage = er.response.data.message
          }
          reject(errorMessage);
        });
      });
    },
    setDishes({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setDishes', payload);
        resolve();
      })
    },
    toggleFavourite({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('toggleFavourite', payload);
        resolve();
      })
    },
    setDishDetails({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setDishDetails', payload);
        resolve();
      })
    },
    resetDishDetails({commit}) {
      return new Promise((resolve, reject) => {
        commit('setDishDetails', dishDetailsTemp);
        resolve();
      })
    },
    updateDishDetails({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('updateDishDetails', payload);
        resolve();
      })
    },
    resetMyFavourites({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetMyFavourites');
        resolve();
      })
    },
  }
}
