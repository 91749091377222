<template>
	<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M17.8573 8.21075H10.4359V0.789354C10.4359 0.353391 10.0826 0 9.64659 0C9.21061 0 8.85724 0.353374 8.85724 0.789354V8.2104H1.43584C0.999876 8.21068 0.646484 8.56377 0.646484 9C0.646484 9.43623 0.999858 9.78935 1.43584 9.78935H8.85688V17.2104C8.85716 17.6472 9.21053 18 9.64648 18C10.0824 18 10.4358 17.6472 10.4358 17.2106V9.78925H17.8569C18.2931 9.78925 18.6465 9.43615 18.6465 8.99989C18.6465 8.56363 18.2931 8.21054 17.8571 8.21054L17.8573 8.21075Z" fill="#001254"/>
	</svg>
</template>

<script>
  export default {
    name: 'PlusBtn'
  }
</script>
