<template>
  <svg width="19" height="29" viewBox="0 0 19 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.50011 0C14.714 0 19 4.06564 19 9.86528C19 11.9348 18.5124 13.3746 17.6069 14.9693L10.1394 28.123V28.1228C10.0107 28.3555 9.76575 28.4997 9.49992 28.4997C9.2341 28.4997 8.98917 28.3555 8.86046 28.1228L1.39299 14.9691C0.487434 13.3746 -0.000152588 11.9348 -0.000152588 9.86528C-0.000152588 4.06571 4.28596 5.9735e-06 9.49974 5.9735e-06L9.50011 0ZM9.50011 5.1152C7.07861 5.1152 5.1156 7.0782 5.1156 9.4997C5.1156 11.9212 7.07861 13.8842 9.50011 13.8842C11.9216 13.8842 13.8846 11.9212 13.8846 9.4997C13.8846 7.0782 11.9216 5.1152 9.50011 5.1152Z" fill="#001254"/>
  </svg>
</template>

<script>
  export default {
    name: 'LocationIcon'
  }
</script>
