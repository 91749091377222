<template>
  <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.40616 7.13743C6.26189 7.1161 6.1281 7.04863 6.02332 6.94477L0.246065 1.25486C0.100181 1.12851 0.0115247 0.94594 0.00104719 0.750472C-0.0094307 0.554834 0.0590754 0.363498 0.190615 0.221445C0.32199 0.0793877 0.504787 -0.000651836 0.695631 8.58307e-06C0.886647 0.000669956 1.0688 0.0820341 1.19937 0.225082L6.5 5.44846L11.8006 0.225082C11.9312 0.0820341 12.1134 0.000669003 12.3044 8.58307e-06C12.4952 -0.00065279 12.678 0.0793877 12.8094 0.221445C12.9409 0.363503 13.0094 0.554839 12.999 0.750472C12.9885 0.945944 12.8998 1.12852 12.7539 1.25486L6.97668 6.94477C6.82483 7.09444 6.61495 7.16538 6.40621 7.13727L6.40616 7.13743Z" fill="#001254"/>
  </svg>
</template>

<script>
  export default {
    name: 'DownArrowIcon'
  }
</script>
