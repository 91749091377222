<template>
	<svg width="59" height="4" viewBox="0 0 59 4" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="59" height="4" rx="2" fill="#E0E0E0"/>
	</svg>
</template>

<script>
  export default {
    name: 'ReviewBarInactive'
  }
</script>