<template>
	<svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M22.9253 10.4915H13.4424V1.00862C13.4424 0.451556 12.9908 0 12.4337 0C11.8766 0 11.4251 0.451533 11.4251 1.00862V10.4911H1.94221C1.38515 10.4914 0.933594 10.9426 0.933594 11.5C0.933594 12.0574 1.38513 12.5086 1.94221 12.5086H11.4247V21.9911C11.425 22.5492 11.8765 23 12.4336 23C12.9906 23 13.4422 22.5492 13.4422 21.9914V12.5085H22.9247C23.4821 12.5085 23.9336 12.0573 23.9336 11.4999C23.9336 10.9424 23.4821 10.4912 22.925 10.4912L22.9253 10.4915Z" fill="#001254"/>
	</svg>
</template>

<script>
  export default {
    name: 'PlusBtnIcon'
  }
</script>
