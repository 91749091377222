<template>
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.574219" y="12.803" width="18" height="3" rx="1.5" transform="rotate(-45 0.574219 12.803)" fill="white"/>
    <rect x="2.12109" y="0.000244141" width="18" height="3" rx="1.5" transform="rotate(45 2.12109 0.000244141)" fill="white"/>
  </svg>
</template>

<script>
  export default {
    name: 'CloseBtnIcon'
  }
</script>
