<template>
  <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="3" rx="1.5" fill="#001254"/>
    <rect y="7" width="18" height="3" rx="1.5" fill="#001254"/>
  </svg>
</template>

<script>
  export default {
    name: 'MenuIcon'
  }
</script>
