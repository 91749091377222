<template>
	<svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M14.0295 0C11.4922 0.857137 9.51503 1.71423 9.79697 5.4286C12.3343 4.85719 14.5934 3.42864 14.0295 0ZM5.97146 5.15717C3.52869 5.15717 0 7.32866 0 11.4001C0 17.1001 3.79992 22.8001 5.97146 22.8001C7.60014 22.8001 8.95729 21.7145 10.0429 21.7145C11.1285 21.7145 12.2144 22.8001 13.8428 22.8001C16.0143 22.8001 18.4571 19.543 19 16.5572C17.1 15.2001 15.7429 14.3857 15.7429 11.943C15.7429 10.3143 17.1 8.68586 18.7287 7.32871C17.9144 6.24308 16.2859 5.15722 14.6573 5.15722C13.0286 5.15722 11.2775 6.44564 10.043 6.51437C8.95738 6.51437 7.60023 5.15722 5.97156 5.15722L5.97146 5.15717Z" fill="#001254"/>
	</svg>
</template>

<script>
  export default {
    name: 'AppleIcon'
  }
</script>
