<template>
	<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0.00572862 6.40616C0.026523 6.26189 0.0922924 6.1281 0.193522 6.02332L5.73969 0.246065C5.86284 0.100181 6.0408 0.0115249 6.23133 0.00104746C6.42203 -0.00943042 6.60853 0.0590757 6.747 0.190616C6.88546 0.32199 6.96348 0.504787 6.96284 0.695631C6.96219 0.886648 6.88289 1.0688 6.74345 1.19937L1.65204 6.5L6.74345 11.8006C6.88288 11.9312 6.96219 12.1134 6.96284 12.3044C6.96348 12.4952 6.88546 12.678 6.747 12.8094C6.60853 12.9409 6.42203 13.0094 6.23133 12.999C6.0408 12.9885 5.86284 12.8998 5.73969 12.7539L0.193522 6.97668C0.0476383 6.82483 -0.0215109 6.61495 0.00588981 6.40621L0.00572862 6.40616Z" fill="#001254"/>
	</svg>
</template>

<script>
  export default {
    name: 'BackBtnIcon'
  }
</script>
