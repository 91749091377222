<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.0308695 4.53446C0.102386 4.32121 0.286909 4.16592 0.509006 4.13197L3.92389 3.60984L5.45671 0.345139C5.55565 0.134101 5.76719 0 5.99983 0C6.23246 0 6.4443 0.1348 6.54294 0.345139L8.07558 3.60984L11.4905 4.13187C11.7129 4.16592 11.8975 4.32111 11.9689 4.53446C12.0404 4.74771 11.9865 4.98266 11.8295 5.14386L9.34099 7.6945L9.92931 11.3033C9.96648 11.5301 9.87075 11.758 9.68261 11.8904C9.49508 12.0223 9.24758 12.0368 9.04687 11.9249L5.99996 10.2403L2.95382 11.9249C2.86291 11.9753 2.76277 11.9999 2.66312 11.9999C2.54188 11.9999 2.42054 11.963 2.31739 11.8903C2.12985 11.7579 2.03383 11.53 2.07069 11.3032L2.65901 7.69441L0.170539 5.14376C0.0135384 4.98265 -0.0404015 4.74771 0.0311165 4.53446L0.0308695 4.53446Z" fill="#001254"/>
  </svg>

</template>

<script>
  export default {
    name: 'StarIcon'
  }
</script>
