import axios from "axios";

function getJsonLocalItem(item) {
  let result = null;
  let localItem = localStorage.getItem(item);
  if (localItem != null && localItem != undefined && localItem != 'undefined') {
    result = JSON.parse(localItem);
  }
  return result;
}

export default {
  state: {
    userProfile: {},
    editProfile: {},
    addressesObj: {},
    addressList: [],
    isNewAddress: localStorage.getItem("isNewAddress") || false,
    selectedAddress: getJsonLocalItem("selectedAddress"),
    confirmAddress: localStorage.getItem("confirmAddress") || false,
    pickupAddress: 'Eslin 20, Athens',
    pickupAddressCoordinates: {lat: 37.98848243701283, lng: 23.760967008598335},
    newAddress: JSON.parse(localStorage.getItem("newAddress")) || null,
    editAddress: null,
    paymentMethods: [
      {method: 'Cash on delivery', details: '', icon: 'cash_icon', value: 0},
      {method: 'POS on delivery', details: '', icon: 'pos_icon', value: 2}
    ],
    selectedPaymentMethod: 0,
    addressLimitationsList: [
      {id: 'under2', message: '', totalLimit: 0},
      {id: 'over2', message: 'Για παραγγελίες εκτός της εμβέλειας των 2km, η ελάχιστη χρέωση είναι 12 ευρώ', totalLimit: 12},
      {id: 'over3', message: 'Για παραγγελίες εκτός της εμβέλειας των 3km, η ελάχιστη χρέωση είναι 18 ευρώ', totalLimit: 18},
      {id: 'over5', message: 'Για παραγγελίες εκτός της εμβέλειας των 5km, η ελάχιστη χρέωση είναι 30 ευρώ', totalLimit: 30},
      {id: 'over8', message: 'Για παραγγελίες εκτός της εμβέλειας των 8km, η ελάχιστη χρέωση είναι 45 ευρώ', totalLimit: 45},
      {id: 'over10', message: 'Για παραγγελίες εκτός της εμβέλειας των 10km καλέστε μας στο 2106420888 για να δούμε αν μπορούμε να εξυπηρετήσουμε κατά συνθήκη!', totalLimit: null},
      {id: 'over25', message: 'Τo Wecook βρίσκεται στην Αθήνα. Σας ευχαριστούμε πολύ για το ενδιαφέρον και ελπίζουμε σύντομα να φτάσουμε και κοντά σας!', totalLimit: null}
    ],
    addressLimitation: getJsonLocalItem("addressLimitation")
  },
  getters: {
    userProfile: state => state.userProfile,
    editProfile: state => state.editProfile,
    addressesObj: state => state.addressesObj,
    addressList: state => state.addressList,
    isNewAddress: state => state.isNewAddress,
    selectedAddress: state => state.selectedAddress,
    confirmAddress: state => state.confirmAddress,
    pickupAddress: state => state.pickupAddress,
    pickupAddressCoordinates: state => state.pickupAddressCoordinates,
    newAddress: state => state.newAddress,
    editAddress: state => state.editAddress,
    paymentMethods: state => state.paymentMethods,
    selectedPaymentMethod: state => state.selectedPaymentMethod,
    addressLimitationsList: state => state.addressLimitationsList,
    addressLimitation: state => state.addressLimitation
  },
  mutations: {
    setUserProfile(state, userObj) {
      state.userProfile = userObj;
    },
    setEditProfile(state, userObj) {
      state.editProfile = userObj;
    },
    setEditProfileProp(state, payload) {
      let prop = payload.prop;
      let value = payload.value;

      state.editProfile[prop] = value;
    },
    setAddressesObj(state, addressesObj) {
      state.addressesObj = addressesObj;
    },
    setAddressList(state, addressListObj) {
      state.addressList = addressListObj;
    },
    setIsNewAddress(state, value) {
      state.isNewAddress = value;
      localStorage.setItem("isNewAddress", value);
    },
    setSelectedAddress(state, addressObj) {
      state.selectedAddress = addressObj;
      localStorage.setItem("selectedAddress", JSON.stringify(addressObj));
    },
    setConfirmAddress(state, value) {
      state.confirmAddress = value;
      localStorage.setItem("confirmAddress", value);
    },
    setNewAddress(state, addressObj) {
      state.newAddress = addressObj;
      localStorage.setItem("newAddress", JSON.stringify(addressObj));
    },
    setEditAddress(state, addressObj) {
      state.editAddress = addressObj;
    },
    setEditAddressProp(state, payload) {
      let prop = payload.prop;
      let value = payload.value;

      state.editAddress[prop] = value;
    },
    setSelectedPaymentMethod(state, method) {
      state.selectedPaymentMethod = method;
    },
    setAddressLimitation(state, limitObj) {
      state.addressLimitation = limitObj;
      localStorage.setItem('addressLimitation', JSON.stringify(limitObj));
    },
    addAddressToAddressList(state, addressObj) {
      state.addressList.push(addressObj);
    },
    toggleSelectedAddress(state, addressId) {
      let matchedAddress = state.addressList.find(el => el.id == addressId);

      state.addressList.forEach((el) => {
        el.selected = false;
      });

      matchedAddress.selected = true;
    },
    resetProfileObjects(state) {
      state.userProfile = {};
      state.addressList = [];
      state.selectedAddress = null;
      state.selectedPaymentMethod = 0;
      state.editAddress = null;
    }
  },
  actions: {
    getUserProfile({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_BASE_URL}/accounts/We%20Cook/customers/info`, {}).then(res => {
          commit('setUserProfile', res.data);
          resolve();
        }).catch(er => {
          console.log(er);
          reject(er);
        });
      })
    },
    getAddressList({commit}, email) {
      return new Promise((resolve, reject) => {
        let url = `${process.env.VUE_APP_BASE_URL}/accounts/We%20Cook/customers/${email}/addresses`;
        axios.get(url, {}).then(res => {
          commit('setAddressesObj', res.data);
          commit('setAddressList', res.data.content);
          resolve();
        }).catch(er => {
          console.log(er);
          reject();
        });
      })
    },
    createNewAddress({commit}, payload) {
      return new Promise((resolve, reject) => {
        let email = payload.email;
        let requestData = payload.requestData;
        let url = `${process.env.VUE_APP_BASE_URL}/accounts/We%20Cook/customers/${email}/addresses`;
        axios.post(url, requestData).then(res => {
          resolve();
        }).catch(er => {
          console.log(er);
          reject();
        });
      })
    },
    updateAddress({}, payload) {
      return new Promise((resolve, reject) => {
        let email = payload.email;
        let requestData = payload.requestData;
        let url = `${process.env.VUE_APP_BASE_URL}/accounts/We%20Cook/customers/${email}/addresses`;
        axios.put(url, requestData).then(res => {
          resolve();
        }).catch(er => {
          console.log(er);
          reject();
        });
      })
    },
    editUserProfile({commit}, userObj) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_BASE_URL}/accounts/We%20Cook/customers/edit`, userObj).then(res => {
          commit('setUserProfile', res.data);
          resolve();
        }).catch(er => {
          console.log(er);
          reject();
        });
      })
    },
    toggleSelectedAddress({commit}, addressId) {
      return new Promise((resolve, reject) => {
        commit('toggleSelectedAddress', addressId);
        resolve();
      })
    },
    setConfirmAddress({commit}, value) {
      return new Promise((resolve, reject) => {
        commit('setConfirmAddress', value);
        resolve();
      })
    },
    setEditProfile({commit}, userObj) {
      return new Promise((resolve, reject) => {
        commit('setEditProfile', userObj);
        resolve();
      })
    },
    setEditProfileProp({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setEditProfile', payload);
        resolve();
      })
    },
    setNewAddress({commit}, addressObj) {
      return new Promise((resolve, reject) => {
        commit('setNewAddress', addressObj);
        resolve();
      })
    },
    setEditAddress({commit}, addressObj) {
      return new Promise((resolve, reject) => {
        commit('setEditAddress', addressObj);
        resolve();
      })
    },
    setEditAddressProp({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setEditAddressProp', payload);
        resolve();
      })
    },
    setAddressLimitation({commit}, limit) {
      return new Promise((resolve, reject) => {
        commit('setAddressLimitation', limit);
        resolve();
      })
    },
    addAddressToAddressList({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('addAddressToAddressList', payload);
        resolve();
      })
    },
    setIsNewAddress({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setIsNewAddress', payload);
        resolve();
      })
    },
    resetProfileObjects({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetProfileObjects');
        resolve();
      })
    },
  }
}
