import axios from "axios";

export default {
  state: {
    rating: 0,
    reviewTitles: [
      {rating: 5, title: 'What did you love?'},
      {rating: 4, title: 'What can we improve?'},
      {rating: 3, title: 'What can we improve?'},
      {rating: 2, title: 'Tell us what went wrong?'},
      {rating: 1, title: 'Tell us what went wrong?'}
    ],
    reviewSteps: [
      {id: 0, name: 'step_1', component: 'review-first-step', active: true},
      {id: 1, name: 'step_2', component: 'review-second-step', active: false},
      {id: 2, name: 'step_3', component: 'review-third-step', active: false},
      {id: 3, name: 'step_4', component: 'review-fourth-step', active: false}
    ]
  },
  getters: {
    rating: state => state.rating,
    reviewTitles: state => state.reviewTitles,
    reviewSteps: state => state.reviewSteps
  },
  mutations: {
    setRating(state, ratingNumber) {
      state.rating = ratingNumber;
    },
    activateReviewStep(state, step) {
      for (let k = 0; k < state.reviewSteps.length; k++) {
        if (k <= step) {
          state.reviewSteps[k].active = true;
        } else {
          state.reviewSteps[k].active = false;
        }
      }
    }
  },
  actions: {
    setRating({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setRating', payload);
        resolve();
      })
    },
    activateReviewStep({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('activateReviewStep', payload);
        resolve();
      })
    }
  }
}
