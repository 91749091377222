<template>
	<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M11.2223 21.4446C16.8679 21.4446 21.4446 16.8679 21.4446 11.2223C21.4446 5.57667 16.8679 1 11.2223 1C5.57667 1 1 5.57667 1 11.2223C1 16.8679 5.57667 21.4446 11.2223 21.4446Z" stroke="#001254" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M23.9998 24.0006L18.4414 18.4423" stroke="#001254" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
  export default {
    name: 'SearchIcon'
  }
</script>
