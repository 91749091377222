<template>
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.31248 2.38766C4.01979 2.37124 5.72716 2.35469 7.43465 2.33827C8.23806 2.33053 9.04156 2.31768 9.84497 2.31506C10.7729 2.31196 10.6407 2.24841 11.4473 3.11439C12.4154 4.15392 12.2359 4.07905 11.1426 4.16345C7.62891 4.43494 3.99219 4.16333 0.467826 4.16322L0.866863 4.57336C0.756392 3.19511 1.19944 2.17746 2.70176 2.32445C3.21277 2.37444 3.20942 1.55378 2.70176 1.50415C1.9521 1.43083 1.03127 1.54319 0.470464 2.13959C-0.155645 2.80541 -0.000370501 3.71032 0.0688777 4.57346C0.0865947 4.79448 0.237362 4.9836 0.467915 4.9836C3.39828 4.9836 6.32852 4.98372 9.25882 4.98372C9.96103 4.98372 12.061 5.32589 12.637 4.93266C12.951 4.71843 12.9334 4.54205 12.9775 4.16249C13.1641 2.55498 12.1715 1.60519 10.7049 1.49702C7.94334 1.29338 5.0811 1.5407 2.31236 1.56737C1.79905 1.57225 1.79789 2.39266 2.31236 2.38766L2.31248 2.38766Z" fill="#001254"/>
        <path d="M5.09486 1.56045C5.03279 0.818699 5.61526 0.852033 6.18404 0.853221C6.77704 0.854412 7.56328 0.69397 7.80055 1.45356C7.95699 1.95417 8.72784 1.74016 8.57014 1.23551C8.44033 0.820125 8.27067 0.368935 7.86458 0.16159C7.35518 -0.0984723 6.60519 0.0337619 6.05909 0.0325706C5.58167 0.0316184 5.00685 -0.0625275 4.62459 0.299653C4.27974 0.626255 4.25902 1.11078 4.29667 1.56045C4.34044 2.08248 5.13897 2.08723 5.09486 1.56045Z" fill="#001254"/>
        <path d="M11.0749 4.98058C10.9202 7.53084 10.6693 10.0717 10.2872 12.5973C10.0982 13.8463 10.2601 14.938 8.84136 15.1485C7.73457 15.3128 6.48327 15.1535 5.36264 15.1559C4.89551 15.1569 4.2049 15.3142 3.78143 15.1592C3.13146 14.9215 3.17803 14.5104 3.10599 13.8561C3.00514 12.9392 2.9968 12.1637 2.73023 11.2664C2.42104 10.2259 1.97419 9.19174 1.85505 8.10246C1.73833 7.03509 1.79402 5.91771 1.81151 4.8465C1.82019 4.3184 1.022 4.31828 1.01343 4.8465C0.984486 6.61683 0.961327 8.30721 1.49192 10.0041C1.77863 10.9208 2.11512 11.8104 2.23625 12.7671C2.35899 13.7364 2.31117 14.7101 2.44792 15.6804C2.47259 15.8551 2.67361 15.9819 2.8327 15.9815C4.73015 15.9775 6.62772 15.9734 8.52493 15.9694C9.33456 15.9676 10.056 16.193 10.506 15.4464C11.223 14.2567 11.1591 12.1895 11.3318 10.8373C11.5804 8.89169 11.754 6.93875 11.8729 4.98048C11.9049 4.45345 11.1067 4.45537 11.0748 4.9806L11.0749 4.98058Z" fill="#001254"/>
        <path d="M4.39585 4.76486C4.38647 8.27402 4.37709 11.7832 4.36771 15.2923C4.36632 15.8202 5.16439 15.821 5.16579 15.2923C5.17517 11.7832 5.18455 8.27402 5.19392 4.76486C5.19543 4.237 4.39736 4.23627 4.39585 4.76486Z" fill="#001254"/>
        <path d="M7.4714 4.81939C7.47337 8.31941 7.47534 11.8194 7.47731 15.3198C7.47765 15.8476 8.27573 15.8485 8.27538 15.3198C8.27341 11.8197 8.27144 8.31972 8.26947 4.81939C8.26924 4.29166 7.47116 4.29069 7.4714 4.81939Z" fill="#001254"/>
        <path d="M1.67394 6.9169C4.83102 6.92643 7.98781 6.93595 11.1449 6.94535C11.6584 6.9469 11.6593 6.12661 11.1449 6.12505C7.98781 6.11553 4.83102 6.10601 1.67394 6.09661C1.16027 6.09506 1.15956 6.91535 1.67394 6.9169Z" fill="#001254"/>
        <path d="M1.88648 9.30967C4.99909 9.34371 8.11171 9.37775 11.224 9.41179C11.7377 9.41738 11.7382 8.5971 11.224 8.59149C8.11141 8.55745 4.9988 8.52341 1.88648 8.48937C1.37269 8.48378 1.37234 9.30406 1.88648 9.30967Z" fill="#001254"/>
        <path d="M10.6392 10.9273C7.91667 10.9423 5.19417 10.9574 2.47167 10.9724C1.95824 10.9753 1.9572 11.7956 2.47167 11.7927C5.19417 11.7777 7.91667 11.7626 10.6392 11.7476C11.1526 11.7447 11.1538 10.9244 10.6392 10.9273Z" fill="#001254"/>
        <path d="M2.8179 13.8901C5.37178 13.9032 7.92555 13.9162 10.4797 13.9293C10.9933 13.9319 10.9939 13.1116 10.4797 13.109C7.92579 13.0959 5.37202 13.0829 2.8179 13.0698C2.30423 13.0672 2.30364 13.8875 2.8179 13.8901Z" fill="#001254"/>
    </svg> 
</template>
  
  <script>
    export default {
      name: 'TrashIcon'
    }
  </script>
  