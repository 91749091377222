import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import HeartWhiteIcon from '@/components/CustomIcons/HeartWhiteIcon.vue'
import HeartBlueIcon from '@/components/CustomIcons/HeartBlueIcon.vue'
import MenuIcon from '@/components/CustomIcons/MenuIcon.vue'
import DownArrowIcon from '@/components/CustomIcons/DownArrowIcon.vue'
import NavIcon from '@/components/CustomIcons/NavIcon.vue'
import FilterIcon from '@/components/CustomIcons/FilterIcon.vue'
import BarBtnIcon from '@/components/CustomIcons/BarBtnIcon.vue'
import LocationIcon from '@/components/CustomIcons/LocationIcon.vue'
import TimeIcon from '@/components/CustomIcons/TimeIcon.vue'
import StarIcon from '@/components/CustomIcons/StarIcon.vue'
import CloseBtnIcon from '@/components/CustomIcons/CloseBtnIcon.vue'
import CloseBtnBlueIcon from '@/components/CustomIcons/CloseBtnBlueIcon.vue'
import BackBtnIcon from '@/components/CustomIcons/BackBtnIcon.vue'
import CheckboxOffIcon from '@/components/CustomIcons/CheckboxOffIcon.vue'
import CheckboxOnIcon from '@/components/CustomIcons/CheckboxOnIcon.vue'
import PlusBtnIcon from '@/components/CustomIcons/PlusBtnIcon.vue'
import NowIcon from '@/components/CustomIcons/NowIcon.vue'
import LaterIcon from '@/components/CustomIcons/LaterIcon.vue'
import SearchIcon from '@/components/CustomIcons/SearchIcon.vue'
import BackArrowIcon from '@/components/CustomIcons/BackArrowIcon.vue'
import MinusBtn from '@/components/CustomIcons/MinusBtn.vue'
import PlusBtn from '@/components/CustomIcons/PlusBtn.vue'
import CommentIcon from '@/components/CustomIcons/CommentIcon.vue'
import RightArrowBtn from '@/components/CustomIcons/RightArrowBtn.vue'
import LeftArrowBtn from '@/components/CustomIcons/LeftArrowBtn.vue'
import AppleIcon from '@/components/CustomIcons/AppleIcon.vue'
import CreditCardIcon from '@/components/CustomIcons/CreditCardIcon.vue'
import CashIcon from '@/components/CustomIcons/CashIcon.vue'
import EmptyStarIcon from '@/components/CustomIcons/EmptyStarIcon.vue'
import FullStarIcon from '@/components/CustomIcons/FullStarIcon.vue'
import ReviewBarActive from '@/components/CustomIcons/ReviewBarActive.vue'
import ReviewBarInactive from '@/components/CustomIcons/ReviewBarInactive.vue'
import FavouriteBtnDisabled from '@/components/CustomIcons/FavouriteBtnDisabled.vue'
import FavouriteBtnEnabled from '@/components/CustomIcons/FavouriteBtnEnabled.vue'
import RadioBtnOnIcon from '@/components/CustomIcons/RadioBtnOnIcon.vue'
import RadioBtnOffIcon from '@/components/CustomIcons/RadioBtnOffIcon.vue'
import TrashIcon from '@/components/CustomIcons/TrashIcon.vue'
import PosIcon from '@/components/CustomIcons/PosIcon.vue'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      heart_white: {
        component: HeartWhiteIcon
      },
      heart_blue: {
        component: HeartBlueIcon
      },
      menu_icon: {
        component: MenuIcon
      },
      nav_icon: {
        component: NavIcon
      },
      down_arrow_icon: {
        component: DownArrowIcon
      },
      filter_icon: {
        component: FilterIcon
      },
      bar_btn: {
        component: BarBtnIcon
      },
      location_icon: {
        component: LocationIcon
      },
      time_icon: {
        component: TimeIcon
      },
      star_icon: {
        component: StarIcon
      },
      close_btn: {
        component: CloseBtnIcon
      },
      close_blue_btn: {
        component: CloseBtnBlueIcon
      },
      back_btn: {
        component: BackBtnIcon
      },
      checkbox_off: {
        component: CheckboxOffIcon
      },
      checkbox_on: {
        component: CheckboxOnIcon
      },
      radio_btn_off: {
        component: RadioBtnOffIcon
      },
      radio_btn_on: {
        component: RadioBtnOnIcon
      },
      add_btn: {
        component: PlusBtnIcon
      },
      now_icon: {
        component: NowIcon
      },
      later_icon: {
        component: LaterIcon
      },
      search_icon: {
        component: SearchIcon
      },
      back_arrow: {
        component: BackArrowIcon
      },
      minus_btn: {
        component: MinusBtn
      },
      plus_btn: {
        component: PlusBtn
      },
      comment_icon: {
        component: CommentIcon
      },
      right_arrow_btn: {
        component: RightArrowBtn
      },
      left_arrow_btn: {
        component: LeftArrowBtn
      },
      apple_icon: {
        component: AppleIcon
      },
      credit_card_icon: {
        component: CreditCardIcon
      },
      cash_icon: {
        component: CashIcon
      },
      empty_star_icon: {
        component: EmptyStarIcon
      },
      full_star_icon: {
        component: FullStarIcon
      },
      review_bar_active: {
        component: ReviewBarActive
      },
      review_bar_inactive: {
        component: ReviewBarInactive
      },
      favourite_btn_disabled: {
        component: FavouriteBtnDisabled
      },
      favourite_btn_enabled: {
        component: FavouriteBtnEnabled
      },
      trash_icon: {
        component: TrashIcon
      },
      pos_icon: {
        component: PosIcon
      }
    }
  }
});
