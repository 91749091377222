<template>
	<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="350.000000pt" height="512.000000pt" viewBox="0 0 350.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#001254" stroke="none">
        <path d="M760 5104 c-88 -38 -130 -109 -130 -223 l0 -79 -162 -4 c-160 -3
        -164 -4 -229 -35 -81 -40 -169 -129 -207 -210 l-27 -58 0 -2095 0 -2095 32
        -66 c40 -81 129 -169 210 -207 l58 -27 722 -3 721 -3 21 27 c26 34 27 64 1 97
        l-21 26 -687 4 c-564 2 -694 5 -727 17 -61 22 -148 114 -166 175 -21 75 -21
        4035 0 4110 18 62 106 154 166 174 22 7 97 15 168 18 l127 6 0 -115 c0 -131 9
        -150 75 -150 72 0 73 3 77 283 3 243 3 249 26 271 l23 23 619 0 619 0 23 -23
        23 -23 3 -504 3 -505 -671 0 -670 0 0 124 c0 123 0 125 -26 145 -34 27 -64 27
        -98 0 -26 -20 -26 -22 -26 -145 l0 -124 -148 0 c-170 0 -182 -5 -182 -77 0
        -79 -99 -73 1152 -73 1067 0 1116 1 1131 18 23 26 22 94 -1 115 -16 14 -43 17
        -165 17 l-147 0 0 371 0 372 128 -6 c70 -3 145 -11 167 -18 60 -20 148 -112
        166 -174 21 -75 21 -4035 0 -4110 -19 -66 -106 -154 -171 -175 -32 -10 -120
        -16 -298 -20 -236 -5 -255 -6 -268 -24 -21 -29 -18 -82 7 -107 20 -20 29 -20
        307 -17 l287 3 58 27 c81 38 170 126 209 207 28 58 33 79 36 169 l4 102 138 0
        c110 0 151 4 201 20 114 36 197 115 239 226 20 54 20 72 20 1459 0 1396 0
        1405 -21 1460 -26 71 -84 143 -145 183 -74 47 -147 62 -301 62 l-132 0 -3 288
        -3 287 -32 66 c-40 81 -129 169 -210 207 -54 25 -68 27 -220 30 l-163 4 0 74
        c0 115 -39 185 -125 225 -38 17 -78 19 -695 18 -563 0 -660 -2 -690 -15z
        m2290 -1814 c0 -457 1 -471 20 -490 12 -12 33 -20 55 -20 22 0 43 8 55 20 19
        19 20 33 20 480 0 253 4 460 8 460 25 0 92 -64 113 -107 l24 -48 3 -1334 c2
        -881 -1 -1353 -8 -1390 -12 -65 -48 -124 -93 -151 -18 -11 -35 -20 -39 -20 -5
        0 -8 418 -8 928 0 924 0 929 -20 947 -12 11 -36 19 -55 19 -19 0 -43 -8 -55
        -19 -20 -18 -20 -23 -20 -957 l0 -938 -75 0 -75 0 0 1545 0 1545 75 0 75 0 0
        -470z"/>
        <path d="M967 4802 c-23 -26 -22 -94 1 -115 17 -15 65 -17 484 -17 524 0 498
        -4 498 77 0 76 22 73 -502 73 -438 0 -466 -1 -481 -18z"/>
        <path d="M967 4502 c-23 -26 -22 -94 1 -115 17 -15 65 -17 484 -17 524 0 498
        -4 498 77 0 76 22 73 -502 73 -438 0 -466 -1 -481 -18z"/>
        <path d="M967 4202 c-23 -26 -22 -94 1 -115 17 -15 65 -17 484 -17 524 0 498
        -4 498 77 0 76 22 73 -502 73 -438 0 -466 -1 -481 -18z"/>
        <path d="M379 3587 c-18 -12 -44 -38 -56 -56 l-23 -34 0 -507 0 -507 23 -34
        c12 -18 38 -44 56 -56 31 -21 45 -23 167 -23 131 0 133 0 153 26 12 15 21 37
        21 49 0 12 -9 34 -21 49 -20 25 -24 26 -119 26 -76 0 -102 4 -114 16 -14 14
        -16 69 -16 454 0 385 2 440 16 454 14 14 117 16 984 16 867 0 970 -2 984 -16
        14 -14 16 -69 16 -454 0 -385 -2 -440 -16 -454 -14 -14 -96 -16 -750 -16 -721
        0 -734 0 -754 -20 -12 -12 -20 -33 -20 -55 0 -22 8 -43 20 -55 20 -20 33 -20
        789 -20 l768 0 34 23 c18 12 44 38 56 56 l23 34 0 507 0 507 -23 34 c-12 18
        -38 44 -56 56 l-34 23 -1037 0 -1037 0 -34 -23z"/>
        <path d="M373 2073 c-12 -2 -34 -18 -48 -34 -25 -30 -25 -34 -25 -196 l0 -165
        29 -29 29 -29 167 0 167 0 29 29 29 29 0 166 0 166 -29 32 -29 33 -149 2 c-81
        0 -158 -1 -170 -4z m227 -223 l0 -70 -75 0 -75 0 0 70 0 70 75 0 75 0 0 -70z"/>
        <path d="M973 2073 c-12 -2 -34 -18 -48 -34 -25 -30 -25 -34 -25 -196 l0 -165
        29 -29 29 -29 167 0 167 0 29 29 29 29 0 166 0 166 -29 32 -29 33 -149 2 c-81
        0 -158 -1 -170 -4z m227 -223 l0 -70 -75 0 -75 0 0 70 0 70 75 0 75 0 0 -70z"/>
        <path d="M1573 2073 c-12 -2 -34 -18 -48 -34 -25 -30 -25 -34 -25 -196 l0
        -165 29 -29 29 -29 167 0 167 0 29 29 29 29 0 166 0 166 -29 32 -29 33 -149 2
        c-81 0 -158 -1 -170 -4z m227 -223 l0 -70 -75 0 -75 0 0 70 0 70 75 0 75 0 0
        -70z"/>
        <path d="M2173 2073 c-12 -2 -34 -18 -48 -34 -25 -30 -25 -34 -25 -196 l0
        -165 29 -29 29 -29 192 0 192 0 29 29 29 29 0 166 0 166 -29 32 -29 33 -174 2
        c-95 0 -183 -1 -195 -4z m277 -223 l0 -70 -100 0 -100 0 0 70 0 70 100 0 100
        0 0 -70z"/>
        <path d="M373 1473 c-12 -2 -34 -18 -48 -34 -25 -30 -25 -34 -25 -196 l0 -165
        29 -29 29 -29 167 0 167 0 29 29 29 29 0 166 0 166 -29 32 -29 33 -149 2 c-81
        0 -158 -1 -170 -4z m227 -223 l0 -70 -75 0 -75 0 0 70 0 70 75 0 75 0 0 -70z"/>
        <path d="M973 1473 c-12 -2 -34 -18 -48 -34 -25 -30 -25 -34 -25 -196 l0 -165
        29 -29 29 -29 167 0 167 0 29 29 29 29 0 166 0 166 -29 32 -29 33 -149 2 c-81
        0 -158 -1 -170 -4z m227 -223 l0 -70 -75 0 -75 0 0 70 0 70 75 0 75 0 0 -70z"/>
        <path d="M1573 1473 c-12 -2 -34 -18 -48 -34 -25 -30 -25 -34 -25 -196 l0
        -165 29 -29 29 -29 167 0 167 0 29 29 29 29 0 166 0 166 -29 32 -29 33 -149 2
        c-81 0 -158 -1 -170 -4z m227 -223 l0 -70 -75 0 -75 0 0 70 0 70 75 0 75 0 0
        -70z"/>
        <path d="M2173 1473 c-12 -2 -34 -18 -48 -34 l-25 -31 0 -465 0 -465 29 -29
        29 -29 192 0 192 0 29 29 29 29 0 466 0 466 -29 32 -29 33 -174 2 c-95 0 -183
        -1 -195 -4z m277 -523 l0 -370 -100 0 -100 0 0 370 0 370 100 0 100 0 0 -370z"/>
        <path d="M373 873 c-12 -2 -34 -18 -48 -34 -25 -30 -25 -34 -25 -196 l0 -165
        29 -29 29 -29 167 0 167 0 29 29 29 29 0 166 0 166 -29 32 -29 33 -149 2 c-81
        0 -158 -1 -170 -4z m227 -223 l0 -70 -75 0 -75 0 0 70 0 70 75 0 75 0 0 -70z"/>
        <path d="M973 873 c-12 -2 -34 -18 -48 -34 -25 -30 -25 -34 -25 -196 l0 -165
        29 -29 29 -29 167 0 167 0 29 29 29 29 0 166 0 166 -29 32 -29 33 -149 2 c-81
        0 -158 -1 -170 -4z m227 -223 l0 -70 -75 0 -75 0 0 70 0 70 75 0 75 0 0 -70z"/>
        <path d="M1573 873 c-12 -2 -34 -18 -48 -34 -25 -30 -25 -34 -25 -196 l0 -165
        29 -29 29 -29 167 0 167 0 29 29 29 29 0 166 0 166 -29 32 -29 33 -149 2 c-81
        0 -158 -1 -170 -4z m227 -223 l0 -70 -75 0 -75 0 0 70 0 70 75 0 75 0 0 -70z"/>
        </g>
    </svg>
</template>

<script>
  export default {
    name: 'PosIcon'
  }
</script>
