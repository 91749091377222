import axios from "axios";

export default {
  state: {
    token: localStorage.getItem('token') || '',
    isNewUser: false,
    signInActiveComponent: localStorage.getItem('signInActiveComponent') || 'sign-in',
  },
  getters: {
    isUserLoggedIn: (state) => {
      return !!state.token;
    },
    isNewUser: state => state.isNewUser,
    signInActiveComponent: state => state.signInActiveComponent
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },
    setIsNewUser(state, value) {
      state.isNewUser = value;
    },
    setSignInActiveComponent(state, value) {
      state.signInActiveComponent = value;
      localStorage.setItem("signInActiveComponent", value);
    },
    logout(state) {
      localStorage.clear();

      state.token = "";
      state.isNewUser = false;

      delete axios.defaults.headers.common["Authorization"];      
    }
  },
  actions: {
    login({commit}, payload) {
      return new Promise((resolve, reject) => {
        const uninterceptedAxios = axios.create();
        let url = `${process.env.VUE_APP_BASE_URL}/accounts/We%20Cook/login`;
        uninterceptedAxios.post(url, payload).then(res => {
          commit("setToken", res.data.token);
          resolve();
        }).catch(er => {
          let errorMessage = er;
          if(er?.response != undefined && er.response?.data != undefined && er.response.data?.message != undefined) {
            errorMessage = er.response.data.message
          }
          reject(errorMessage);
        });
      });
    },
    register({commit}, payload) {
      return new Promise((resolve, reject) => {
        const uninterceptedAxios = axios.create();
        let url = `${process.env.VUE_APP_BASE_URL}/accounts/We%20Cook/customers/noRecaptcha`;
        uninterceptedAxios.post(url, payload).then(res => {
          resolve();
        }).catch(er => {
          let errorMessage = er;
          if(er?.response != undefined && er.response?.data != undefined && er.response.data?.message != undefined) {
            errorMessage = er.response.data.message
          }
          reject(errorMessage);
        });
      });
    },
    logout({commit}) {
      return new Promise((resolve, reject) => {
        commit("logout");
        resolve();
      });
    },
    setIsNewUser({commit}, value) {
      return new Promise((resolve, reject) => {
        commit("setIsNewUser", value);
        resolve();
      });
    },
    setSignInActiveComponent({commit}, value) {
      return new Promise((resolve, reject) => {
        commit("setSignInActiveComponent", value);
        resolve();
      });
    },
  }
}
