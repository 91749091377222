import axios from "axios";

export default {
  state: {
    chefDetails: {},
    chefList: [],
    selectedChefs: []
  },
  getters: {
    chefDetails: state => state.chefDetails,
    chefList: state => state.chefList,
    selectedChefs: state => state.selectedChefs
  },
  mutations: {
    setChefDetails(state, chefId) {
      state.chefDetails = state.chefList.find(el => el.id == chefId);
    },
    setChefList(state, chefListObj) {
      state.chefList = chefListObj;
    },
    setSelectedChefs(state, chefObj) {
      state.selectedChefs = chefObj;
    }
  },
  actions: {
    getChefDetails({commit}, chefId) {
      return new Promise((resolve, reject) => {
        localStorage.setItem('chefId', chefId);
        commit('setChefDetails', chefId);
        resolve();
      })
    },
    getChefList({commit}) {
      return new Promise((resolve, reject) => {
        let url = `${process.env.VUE_APP_BASE_URL}/wecook/chef/list?accountId=${process.env.VUE_APP_ACCOUNT_ID}`;
        axios.get(url, {}).then(res => {
          let response = res.data.content.filter((el) => {
            return el.avatar != null;
          }).map((el) => {
            let avatar = el.avatar.url.replace(" ", "%20");

            return {
              id: el.id,
              chefName: el.chefName.en,
              avatar: avatar,
              content_en: el.content.en,
              review: el.review,
              tags: el.tags
            }
          });
          commit('setChefList', response);
          resolve();
        }).catch(er => {
          console.log(er);
          reject();
        });
      })
    },
    setSelectedChefs({commit}, chefObj) {
      return new Promise((resolve, reject) => {
        commit('setSelectedChefs', chefObj);
        resolve();
      })
    },
  }
}
