import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from "axios";
import vuetify from './plugins/vuetify'
import GmapVue from 'gmap-vue'
import VueCountryCode from "vue-country-code";
import VueGtag from "vue-gtag"
import * as Sentry from "@sentry/vue";

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyDsHq1AMgr5nyKZGyynnriS3VakqiErCcE',
    libraries: "places,geocoding,geometry",
    language: 'en'
  },
  installComponents: true
})

Vue.use(VueCountryCode);

Vue.use(VueGtag, {
  config: {
    id: 'G-ZR9XD544KW'
  }
}, router);

Sentry.init({
  Vue,
  dsn: "https://726437e0285604cd26e9d035efb701b2@o4506308503666688.ingest.sentry.io/4506353408212992",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/wecookuat.mobics.gr\/RemenuAdmin/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// const isProd = process.env.VUE_APP_NODE_ENV == 'production';
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

Vue.config.productionTip = false;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch("logout").then(() => {
        if (router.currentRoute.name != 'first_screen') {
          router.go("/");
        }
      });
    }
    throw error;
  }
);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
