<template>
	<svg width="26" height="17" viewBox="0 0 26 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1.72624 0C0.775017 0 0 0.774811 0 1.72624V14.3846C0 15.3358 0.774811 16.1109 1.72624 16.1109H24.1661C25.1173 16.1109 25.8923 15.336 25.8923 14.3846V1.72624C25.8923 0.775017 25.1175 0 24.1661 0H1.72624ZM1.72624 0.575329H24.1661C24.8086 0.575329 25.3418 1.08404 25.3167 1.72599V2.87664H0.57538V1.72599C0.57538 1.08341 1.08369 0.575329 1.72604 0.575329H1.72624ZM0.575586 3.45223H25.3169V5.7538H0.575586V3.45223ZM0.575586 6.32913H25.3169V14.3845C25.3169 15.027 24.8086 15.5351 24.1663 15.5351H1.72645C1.08387 15.5351 0.575791 15.0268 0.575791 14.3845L0.575586 6.32913ZM16.9739 9.20603C15.8652 9.20603 14.9601 10.1111 14.9601 11.2198C14.9601 12.3285 15.8652 13.2246 16.9739 13.2246C17.5374 13.2246 18.0474 12.9966 18.4124 12.6221C18.7788 12.9956 19.2899 13.2333 19.8508 13.2333C20.9596 13.2333 21.8646 12.3283 21.8646 11.2196C21.8646 10.1108 20.9596 9.20578 19.8508 9.20578C19.2853 9.20578 18.7704 9.44057 18.4033 9.81701C18.0385 9.44496 17.5354 9.20578 16.9739 9.20578V9.20603ZM16.9739 9.77232C17.7718 9.77232 18.4124 10.4219 18.4124 11.2198C18.4124 12.0177 17.7718 12.6583 16.9739 12.6583C16.176 12.6583 15.5355 12.0177 15.5355 11.2198C15.5355 10.4219 16.176 9.77232 16.9739 9.77232ZM19.8508 9.78115C20.6487 9.78115 21.2893 10.4217 21.2893 11.2196C21.2893 12.0175 20.6487 12.658 19.8508 12.658C19.4115 12.658 19.026 12.4552 18.7629 12.1455C18.908 11.8678 18.9877 11.5543 18.9877 11.2196C18.9877 10.8843 18.9084 10.5626 18.7629 10.2847C19.026 9.97684 19.4115 9.78118 19.8508 9.78118V9.78115Z" fill="#001254"/>
	</svg>
</template>

<script>
  export default {
    name: 'CreditCardIcon'
  }
</script>
