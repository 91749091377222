<template>
  <svg width="36" height="30" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.3139 0C20.3868 0 18.3886 5.26045 18.3886 5.26045C18.3886 5.26045 16.3926 0 10.4632 0C4.53768 0 0.96875 5.64911 0.96875 10.305C0.96875 18.1077 18.3886 30 18.3886 30C18.3886 30 35.8084 18.1104 35.8084 10.3066C35.8084 5.64905 32.2409 0 26.3139 0Z" fill="#001254"/>
  </svg>
</template>

<script>
  export default {
    name: 'HeartBlueIcon'
  }
</script>
