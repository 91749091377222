<template>
	<svg width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.78728 9L1 5L4.78728 1" stroke="#001254" stroke-miterlimit="10" stroke-linecap="round"/>
		<path d="M1.33203 5H17.8067" stroke="#001254" stroke-miterlimit="10" stroke-linecap="round"/>
	</svg>
</template>

<script>
  export default {
    name: 'BackArrowIcon'
  }
</script>
