<template>
  <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.1458 16.2385C20.329 15.8722 20.8173 15.7502 21.1836 15.9334C21.5499 16.1165 21.6719 16.6048 21.4888 16.9712C19.4742 20.6341 15.6283 22.8926 11.4769 22.8926C5.12794 22.8926 0 17.7646 0 11.4769C0 5.12794 5.12794 0 11.4769 0C17.2765 0 22.2213 4.39537 22.8317 10.195L23.8085 9.27934C24.1136 8.97418 24.6021 8.97418 24.9073 9.27934C25.2124 9.5845 25.2124 10.073 24.9073 10.3781L22.7095 12.5759C22.4044 12.8811 21.9159 12.8811 21.6107 12.5759L19.4129 10.3781C19.1078 10.073 19.1078 9.58451 19.4129 9.27934C19.7181 8.97418 20.2066 8.97418 20.5117 9.27934L21.2444 10.012C20.5729 5.18942 16.3609 1.52661 11.477 1.52661C5.98281 1.52661 1.52653 5.98289 1.52653 11.4771C1.52653 16.9104 5.98281 21.3667 11.477 21.3667C15.0787 21.3667 18.3755 19.4133 20.1455 16.2388L20.1458 16.2385Z" fill="#001254"/>
    <path d="M10.6836 5.12786C10.6836 4.70055 11.0499 4.33423 11.4772 4.33423C11.9045 4.33423 12.2099 4.70055 12.2099 5.12786V11.1106L14.6518 13.5525C14.9569 13.8577 14.9569 14.3461 14.6518 14.6513C14.3466 14.9565 13.8581 14.9565 13.553 14.6513L10.9279 12.0262C10.8057 11.8431 10.6837 11.6599 10.6837 11.4768V5.12774L10.6836 5.12786Z" fill="#001254"/>
  </svg>
</template>

<script>
  export default {
    name: 'TimeIcon'
  }
</script>
