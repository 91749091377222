import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import goTo from "vuetify/lib/services/goto";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'first_screen',
    component: () => import('../views/FirstScreen.vue'),
    meta: {
      authenticated: false
    }
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('../views/MainFeed.vue'),
    meta: {
      authenticated: false
    }
  },
  {
    path: '/chef_profile',
    name: 'chef_profile',
    component: () => import('../views/ChefProfile.vue'),
    meta: {
      authenticated: false
    }
  },
  {
    path: '/my_favourites',
    name: 'my_favourites',
    component: () => import('../views/MyFavourites.vue'),
    meta: {
      authenticated: false
    }
  },
  {
    path: '/my_orders',
    name: 'my_orders',
    component: () => import('../views/MyOrders.vue'),
    meta: {
      authenticated: true
    }
  },
  {
    path: '/order_details',
    name: 'order_details',
    component: () => import('../views/OrderDetails.vue'),
    meta: {
      authenticated: true
    }
  },
  {
    path: '/my_details',
    name: 'my_details',
    component: () => import('../views/MyDetails.vue'),
    meta: {
      authenticated: true
    }
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('../views/Support.vue'),
    meta: {
      authenticated: false
    }
  },
  {
    path: '/basket_review',
    name: 'basket_review',
    component: () => import('../views/BasketReview.vue'),
    meta: {
      authenticated: false
    }
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/Checkout.vue'),
    meta: {
      authenticated: true
    }
  },
  {
    path: '/order_confirmed',
    name: 'order_confirmed',
    component: () => import('../views/OrderConfirmed.vue'),
    meta: {
      authenticated: true
    }
  },
  {
    path: '/reset_password',
    name: 'reset_password',
    component: () => import('../views/ResetPassword.vue'),
    meta: {
      authenticated: false
    }
  },
  {
    path: '/terms_and_conditions',
    name: 'terms_and_conditions',
    component: () => import('../views/TermsAndConditions.vue'),
    meta: {
      authenticated: false
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authenticated) && !store.getters.isUserLoggedIn) {
    next({ path: 'menu' });
  }
  else {
    next();
  }
})

export default router
