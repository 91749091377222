<template>
  <svg width="62" height="7" viewBox="0 0 62 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="62" height="7" rx="3.5" fill="#F2F2F2"/>
  </svg>
</template>

<script>
  export default {
    name: 'BarBtnIcon'
  }
</script>
