<template>
	<svg width="27" height="18" viewBox="0 0 27 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M8.8087 8.51538C8.8087 8.80719 9.04746 9.04597 9.33929 9.04597H9.9229C10.098 9.50757 10.3686 9.93733 10.7347 10.3087C11.4138 10.9878 12.3263 11.3539 13.2601 11.3539C13.6899 11.3539 14.1249 11.2743 14.5388 11.1152C14.8147 11.0091 14.9473 10.7013 14.8412 10.4308C14.7351 10.1549 14.4273 10.0222 14.1568 10.1283C13.2336 10.4838 12.183 10.261 11.4827 9.56064C11.3289 9.40677 11.1962 9.23169 11.0901 9.0513H13.4989C13.7907 9.0513 14.0295 8.81254 14.0295 8.52071C14.0295 8.22887 13.7907 7.99012 13.4989 7.99012H10.7611C10.7558 7.89461 10.7505 7.79381 10.7558 7.69831H13.8119C14.1037 7.69831 14.3425 7.45955 14.3425 7.16772C14.3425 6.87588 14.1037 6.63713 13.8119 6.63713H11.0317C11.1485 6.41429 11.297 6.20737 11.4827 6.02165C12.1831 5.32128 13.2336 5.09847 14.1568 5.45395C14.4274 5.56006 14.7351 5.42211 14.8412 5.15152C14.9473 4.87563 14.8093 4.5732 14.5388 4.46711C13.2229 3.96306 11.7321 4.27611 10.7346 5.27357C10.3367 5.67149 10.0555 6.13839 9.88568 6.63711H9.33918C9.04737 6.63711 8.80859 6.87586 8.80859 7.1677C8.80859 7.45953 9.04735 7.69829 9.33918 7.69829H9.69466C9.69466 7.79379 9.69466 7.8946 9.69997 7.9901H9.33918C9.04737 7.98479 8.80859 8.22355 8.80859 8.51535L8.8087 8.51538Z" fill="#001254"/>
		<path d="M24.3796 2.17C24.1515 0.939083 23.0691 0 21.7746 0H2.65285C1.18847 0 0 1.18847 0 2.65285V12.9139C0 14.2085 0.933796 15.2909 2.17 15.519C2.39814 16.7499 3.48051 17.689 4.77507 17.689H23.8914C25.3558 17.689 26.5443 16.5005 26.5443 15.0361V4.7804C26.5496 3.48051 25.6105 2.40347 24.3796 2.17004L24.3796 2.17ZM1.06142 12.9191V2.65799C1.06142 1.78255 1.7777 1.06632 2.6531 1.06632H21.7695C22.6449 1.06632 23.3611 1.7826 23.3611 2.65799V12.9191C23.3611 13.7945 22.6449 14.5107 21.7695 14.5107H2.6531C1.77766 14.5107 1.06142 13.7945 1.06142 12.9191ZM25.4885 15.0413C25.4885 15.9168 24.7723 16.633 23.8969 16.633H4.77516C4.08542 16.633 3.49118 16.1873 3.27367 15.5719H21.7691C23.2335 15.5719 24.422 14.3834 24.422 12.919V3.27886C25.0375 3.49639 25.4831 4.08532 25.4831 4.78035V15.0414L25.4885 15.0413Z" fill="#001254"/>
	</svg>
</template>

<script>
  export default {
    name: 'CashIcon'
  }
</script>
