import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth';
import chefs from './chefs';
import dishes from './dishes';
import filters from './filters';
import orders from './orders';
import profile from './profile';
import reviews from './reviews';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    chefs,
    dishes,
    filters,
    orders,
    profile,
    reviews
  }
})
