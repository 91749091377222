<template>
  <div id="app-loading">
    <div class="loading-child"></div>
    <div class="loading-child frame-container">
      <div class="no-animate">
        <v-img
          width="250"
          src="@/assets/frame-blue.svg"
          class="ma-auto"
        ></v-img>
      </div>
    </div>
    <div class="loading-child footer-container">
      <div>
        <v-img
          width="111"
          src="@/assets/wecook-footer-logo.svg"
        ></v-img>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppLoading',
    data() {
      return {

      };
    },
    methods: {
      
    }
  };
</script>

<style type="text/css" scoped>
  #app-loading {
    background: #5BE789;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .loading-child {
    flex: 1;
  }

  .frame-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('~@/assets/frame-white-background.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    animation:spin 1s linear infinite;
  }

  .footer-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 80px;
  }

  .frame-container .no-animate {
    animation: counter-spin 1s linear infinite;
  }

  @keyframes spin {
    from {
      transform:rotate(0deg)
    }
    to {
      transform:rotate(360deg)
    }
  }

  @keyframes counter-spin {
    from {
      transform:rotate(0deg)
    }
    to {
      transform:rotate(-360deg)
    }
  }
</style>